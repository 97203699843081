@import "../../../styles/mixins";

.tipsWrapper {
	position: fixed;
	bottom: 0;
	top: 0;
	right: 0;
	left: 0;
	z-index: 2222222222;
	background-color: var(--main-bg);
	padding: 0 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 100px;

	.heading {
		max-width: 600px;
		text-align: center;
		font-size: 30px;
		color: var(--white);
    line-height: 150%;
		margin-bottom: 100px;
		span {
			font-weight: bold;
		}

		@include media-query('mobile') {
			font-size: 20px;
		}
	}
}
.firstSectionIcons {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 60px;

	p {
		font-size: 30px;
		color: var(--white);
	}
}

.navWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 50px;
	font-size: 30px;
	color: var(--white);

	@include media-query('mobile') {
		font-size: 25px;
	}
	div {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	svg {
		&:hover {
			cursor: pointer;
		}
	}
}

svg {
	@include hover();
}

.skipWrapper {
	display: flex;
	align-items: flex-end;
	justify-content: center;
	gap: 25px;
	font-size: 25px;
	line-height: 15px;
	color: var(--text-gray-1);
	@include media-query('mobile') {
		font-size: 20px;
	}
	@include hover();
	&:hover {
		cursor: pointer;
		color: var(--white);
		svg {
			@include hover();
			path {
				fill: var(--white);
			}
		}
	}
}
