@import "../../../styles/mixins";

.inputWrapper {
	position: relative;
	width: 100%;
	margin-bottom: 30px;
}

.disableInputButton {
	color: var(--main-green);
	position: absolute;

	top: 50%;
	transform: translateY(-50%);

	&:dir(ltr) {
		right: 20px;
	}
	&:dir(rtl) {
		left: 20px;
	}

	@include hover();

	&:hover {
		color: var(--white)
	}
}

.label {
	font-size: 14px;
	font-weight: bold;
	color: var(--white);
	margin-bottom: 20px;
}

.disabledInput,
.input {
	width: 100%;
	padding: 15px 20px;
	background-color: transparent;
	border-radius: 8px;
	border: 1px solid var(--secondary-border);
	color: var(--text-gray-1);
	font-size: 14px;

	&:hover {
		cursor: pointer;
	}

	&::placeholder {
		color: var(--text-gray-1);
	}

	&:focus {
		color: var(--white);
		border: 1px solid var(--hover-gray-nav);
		outline: none;
		&::placeholder {
			color: var(--text-gray);
		}
	}

	&:disabled {
		color: var(--text-gray-1);
	}

	::-webkit-input-placeholder { /* WebKit, Blink, Edge */
		color: var(--text-gray-1);
	}
	:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		color: var(--text-gray-1);
		opacity:  1;
	}
	::-moz-placeholder { /* Mozilla Firefox 19+ */
		color: var(--text-gray-1);
		opacity:  1;
	}
	:-ms-input-placeholder { /* Internet Explorer 10-11 */
		color: var(--text-gray-1);
	}
	::-ms-input-placeholder { /* Microsoft Edge */
		color: var(--text-gray-1);
	}

	::placeholder { /* Most modern browsers support this now. */
		color: var(--text-gray-1);
	}
}

.disabledInput {
	&:focus {
		border: 1px solid var(--secondary-border);
		color: var(--text-gray-1);
		&::placeholder {
			color: var(--text-gray-1);
		}
	}
}
