@import "../../../styles/mixins";

.likeIcon {
	@include hover();
	&:hover {
		cursor: pointer;
		fill: var(--white)
	}

	@include media-query('mobile') {
		&:hover {
			cursor: default;
			fill: transparent;
		}
	}
}

.likedIcon {
	fill: var(--white);
}

.likesWrapper {
  max-height: 110px;
  white-space: nowrap;

  svg {
    display: flex;
    flex-shrink: 0;
  }
}

.likesWrapperRow {
  @include flex-row(8px);
  align-items: center;
}

.likesWrapperColumn {
  @include flex-column(10px);
  align-items: center;
  justify-content: center;

  @include media-query("mobile") {
    position: absolute;
    bottom: -33px;
    right: 0;
    @include flex-row(8px);
  }
}

.counter {
  @include text-7();
  @include fs-normal(var(--white));
}

.likeIcon {
	@include hover()
}
