@import "../../../styles/mixins";

.button {
	background: var(--main-button);
	color: var(--white);
	font-size: 14px;
	font-weight: bold;
	border-radius: 8px;
	@include hover();

	&:hover {
		background: var(--hover-red);
	}

	@include media-query('mobile') {
		&:hover {
			background: var(--main-button);
		}
	}
}
