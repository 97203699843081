@import "../../../styles/mixins";

.modalWrapper {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	background-color: var(--modal-bg);
	z-index: 99999999999999;

	display: flex;
	align-items: center;
	justify-content: center;
}

.modalContentWrapper {
	padding: 40px;
	border-radius: var(--br-8);
	background-color: var(--main-gray-hover);

	@include media-query('mobile') {
		margin: 0 15px;
	}
}

.closeIconWrapper {
	width: 100%;
	display: flex;
	align-items: flex-end;
	justify-content: flex-end;

	svg:hover {
		cursor: pointer;
	}
}
