@import "../../../styles/mixins";

.sectionWrapper {
  @include width-full();
  @include flex-column(40px);

  @include media-query("mobile") {
    @include width-full(767px);
    @include flex-column(30px);
  }
}

.contentWrapper {
  @include flex-column(40px);

  @include media-query("mobile") {
    @include flex-column();
  }
}

.emptyWrapper {
	width: 100%;
	padding-top: 70px;
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	span {
		font-size: 20px;
		color: var(--secondary-border);
	}
	svg {
		margin-bottom: 30px;
	}
}

.loaderWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.cardListWrapper {
  @include flex-row(40px);
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr;
  row-gap: 20px;
  column-gap: 20px;

  @media screen and (min-width: 1450px) and (max-width: 1600px) {
    grid-template-columns: 1fr 1fr;
  }

	@media screen and (max-width: 1450px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (max-width: 1300px) {
		grid-template-columns: 1fr;
	}

  @include media-query("mobile") {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 20px;
		padding-bottom: 75px;
  }

	@include media-query("desktopXL") {
		grid-template-columns: 1fr 1fr 1fr;
	}

	@include media-query("desktopXXL") {
		grid-template-columns: 1fr 1fr 1fr 1fr;
	}
}

.advertisement {
  width: 100%;
  height: 120px;
  border-radius: var(--br-20);
}
