@import "../../styles/mixins";

.headerWrapper {
	justify-content: space-between;
}

.wrapper {
	background: var(--main-bg);
}
.container {
	max-width: 900px;
	margin: 0 auto;
	@include flex-column(70px);
	justify-content: space-between;
	padding-top: 150px;
	padding-bottom: 111px;


	@include media-query("mobile") {
		gap: 30px;
	}

	@include media-query("mobile") {
		padding: 95px 15px;
	}
}

.titleWrapper {
	@include flex-column(30px);
	@include width-full(460px);
}

.subheadingText {
	@include text-6();
	@include fs-normal();
	max-width: 433px;
}

.colorfulText {
	color: var(--main-green);
	@include hover();

	&:hover {
		color: var(--white);
		cursor: pointer;
	}
}

.content {
	@include flex-column(70px);

	@include media-query("mobile") {
		gap: 30px;
	}
}

.title {
	@include text-3();
	@include fs-normal(var(--white));
}

.boldDecor {
	font-weight: bold;
}

.subtitle {
	@include text-6();
	@include fs-normal();
}

.link {
	@include text-6();
	@include fs-normal(var(--white));
}

.iconWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr;
	gap: 20px;

	@include media-query("mobile") {
		margin-bottom: 70px;
		max-width: inherit;
	}

	svg {
		width: 220px;
		@include media-query("mobile") {
			width: 162px;
		}
	}

	@include media-query("mobile") {
		gap: 20px;
	}
}

.advertisementIcon {
	width: 220px;
	height: 140px;

	@include media-query("mobile") {
		width: auto;
		height: auto;
	}
}

.advertisementWrapper {
	@include flex-row(90px);
	align-items: center;

	@include media-query("mobile") {
		@include flex-column(30px);
	}
}

.advertisementTitleWrapper {
	@include flex-column(30px);

	@include media-query("mobile") {
		order: 2;
	}
}

.partnershipWrapper {
	@include flex-row(90px);
	align-items: center;
	border-top: 1px solid var(--secondary-border);
	border-bottom: 1px solid var(--secondary-border);
	padding-bottom: 16px;

	@include media-query("mobile") {
		@include flex-column(30px);
		padding-bottom: 30px;
		padding-top: 30px;

		img {
			width: 100%;
			height: auto;
			order: 1;
		}
	}
}

.questionsWrapper {
	@include flex-column(30px);
	justify-content: center;
	align-items: center;
	width: fit-content;
	margin: auto;
}

.questionText {
	@include text-3();
	@include fs-normal();
}

.questionLink {
	@include text-3();
	@include fs-normal(var(--white));
	@include hover();
	&:hover {
		color: var(--main-button);
	}
}
