@import "../../../../styles/mixins";

.wrapper {
  @include flex-row(20px);
	min-width: 310px;
	align-items: center;
	justify-content: flex-start;
	padding: 10px 20px 10px 10px;
	border-radius: var(--br-8);
	border: 1px solid var(--secondary-border);
	@include hover();

	&:hover {
		cursor: pointer;
		border: 1px solid var(--white-hover);
		background-color: var(--white-hover);

		.fullName {
			color: var(--main-dark);
		}
	}

	@include media-query('mobile') {
		&:hover {
			cursor: default;
			border: 1px solid var(--secondary-border);
			background-color: transparent;

			.fullName {
				color: var(--white);
			}
		}
	}
}

.image {
  width: 130px;
  height: 130px;
  border-radius: var(--br-8);
  object-fit: cover;
}

.textWrapper {
  @include flex-column(5px);
}

.fullName {
  @include text-6();
  @include fs-normal();
	@include hover();

	line-height: 14px;
	color: var(--white);
  text-transform: uppercase;
}

.description {
  @include text-8();
  @include fs-normal();
	color: var(--main-green);
}
