@import "../../../styles/mixins";

.filterButtonWrapper {
	@include flex-row(30px);
}

.button {
	@include fs-bold(var(--text-gray-1));
}

.selectedFilter {
	color: var(--white);
}

.filterButtonMobile {
	@include flex-row(10px);
	align-items: center;
	@include text-6();
	@include fs-bold();
	padding: 15px 20px;
	border: 1px solid var(--secondary-border);
	border-radius: var(--br-10);
}
