@import "../../styles/mixins";

.headerWrapper {
	justify-content: space-between;
}

.subtitle {
	@include text-3();
	color: var(--white);
	@include media-query("mobile") {
		margin-bottom: 15px;
	}
}

.text {
	@include text-6();
	color: var(--text-gray-1);
}
.container {
	@include flex-column(70px);
	justify-content: flex-start;
	padding: 150px 112px 111px 206px;
	background: var(--main-bg);

	@include media-query("mobile") {
		gap: 30px;
	}

	@include media-query("mobile") {
		padding: 95px 15px;
	}
}

.sectionWrapper {
	max-width: 600px;
}
.title {
	@include width-full(780px);
}

.sectionWrapper {
	@include flex-column(30px);
	@include width-full(780px);
}
