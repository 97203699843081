@import "../../styles/mixins";

.loginPageWrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	background-image: url("../../static/imgs/bg.jpg");
	background-position: right;
	background-repeat: no-repeat;
	background-size: cover;

	@include media-query("mobile") {
		background-size: cover;
		background-image: url("../../static/imgs/mobileBg.jpg");
	}
}

.loginPageContentWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 275px;
	padding: 60px 0;
}

.toggle {
	background: var(--main-button);
}

.headerWrapper {
	background: transparent;
	position: static;
	width: 100%;
}

.loginTitle {
	font-size: 20px;
	font-style: normal;
	color: var(--white);
	margin-bottom: 40px;

	@include media-query("mobile") {
		font-size: 24px;
		margin-bottom: 20px;
	}
}

.confirmDescriptionDesc {
	font-size: 12px;
	font-style: normal;
	color: var(--white);
	margin-bottom: 40px;
	text-align: center;
	line-height: 20px;
	span {
		font-weight: bold;
		padding: 0 5px;
	}

	@include media-query("mobile") {
		margin-bottom: 10px;
	}
}

.loginDesc {
	font-size: 12px;
	font-style: normal;
	color: var(--white);
	margin-bottom: 20px;
	text-align: center;
	line-height: 150%;

	@include media-query("mobile") {
		font-size: 14px;
		margin-bottom: 15px;
	}
}

.rememberWrapper {
	display: flex;
	align-items: center;

	&:dir(ltr) {
		justify-content: flex-start;
	}
	&:dir(rtl) {
		justify-content: flex-end;
	}
	width: 100%;
	margin-top: 10px;
	margin-bottom: 25px;
	p {
		&:dir(rtl) {
			margin-right: 10px;
		}
		font-size: 14px;
		font-weight: bold;
		color: var(--white);
	}

	@include media-query("mobile") {
		margin-bottom: 20px;
	}
}

.privacyCodeDescription {
	font-style: normal;
	font-size: 12px;
	line-height: 150%;
	text-align: center;
	color: var(--white);
	div {
		margin-top: 30px;
	}
	a {
		color: var(--main-button);
		text-decoration: underline;
		font-weight: bold;
	}
	.contactUs {
		margin-top: 20px;
		color: var(--text-gray-1);
	}

	@include media-query("mobile") {
		.contactUs {
			margin-top: 10px;
		}
		div {
			margin-top: 20px;
		}
	}
}

.confirmButtonWrapper {
	width: 100%;
	margin-top: 40px;

	@include media-query('mobile') {
		margin-top: 30px;
	}
}

.confirmButton {
	height: 47px;
	padding: 0 87px;
	width: 100%;
	@include media-query('mobile') {
		height: 40px;
	}
}

.privacyConfirmation,
.privacy {
	margin-top: 15px;
	div {
		margin-top: 15px;
		font-size: 10px;
		font-weight: normal;
		color: var(--text-gray-1);
		line-height: 15px;

		a {
			color: var(--main-green);
			margin-left: 3px;
			border-bottom: .5px solid var(--main-green);
			@include hover();
			&:hover {
				color: var(--white);
				border-bottom: .5px solid var(--white);
			}
		}
	}

	@include media-query('mobile') {
		margin-top: 10px;
		div {
			margin-top: 10px;
		}
	}
}

.privacyConfirmation {
	text-align: center;
}

.resendButtonWrapper {
	width: 100%;
	position: relative;

	.timer {
		position: absolute;
		right: 30px;
		bottom: -1px;
		height: 47px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: var(--white-opacity);

		@include media-query('mobile') {
			height: 40px;
		}
	}
}

.resendButton {
	padding: 0;
	margin-top: 30px;
  width: 100%;
	height: 47px;
	padding-right: 30px;
	&:disabled {
		background: var(--main-gray-hover);
		color: var(--white-opacity);
		&:hover {
			background: var(--main-gray-hover);
			color: var(--white-opacity);
		}
	}

	@include media-query('mobile') {
		margin-top: 20px;
		height: 40px;
	}
}

.resendButtonEnable {
	padding-right: 0px;
}

.loginButtonWrapper {
	width: 100%;
}

.loginButton {
	width: 100%;
	height: 47px;

	@include media-query('mobile') {
		height: 40px;
	}
}
