@import "../../../styles/mixins";

.wrapper {
	position: fixed;
	left: 2.2rem;
	top: 0;
	min-height: 100vh;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	z-index: 99;
	padding-top: 150px;
}

.itemWrapper {
	@include flex-center();
	flex-direction: column;
	padding: 0 20px;

	&:hover {
		path {
			stroke: var(--white);
		}

		svg {
			stroke: var(--white);
		}

		circle {
			stroke: var(--white);
		}
	}
}

.itemSemanticWrapper {
	margin-bottom: 1.8rem;
}

ul li.itemSemanticWrapper:last-child {
	margin-bottom: 0;
}

.itemWrapper:hover {
	cursor: pointer;
	color: var(--hover-gray);
}

.itemTitle {
	@include text-8();
	@include fs-normal();
}

.itemTitleActive {
	color: var(--white);
}

.icon {
	margin-bottom: 2px;
}

.topSidebarWrapper {
	margin-bottom: 100px;
}
