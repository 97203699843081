@import "../../styles/mixins";

.wrapperMobile,
.headerWrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	background-color: var(--main-bg);
	z-index: 111111111111111111111;
	justify-content: flex-end;
}

.wrapperMobile {
	padding: 0;
	height: 50px;
	justify-content: flex-end;

	@include media-query("mobile") {
		height: 54px;
	}
}

.headerWrapper {
	justify-content: space-between;
}
.emptyMobile,
.empty {
	width: 400px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.emptyMobile {
	width: 54px;
	height: 54px;
}

.logoWrapperMobile,
.logoWrapper {
	cursor: pointer;
	width: 200px;
	height: 50px;
	@include flex-center();

	@include media-query("mobile") {
		display: flex;
		flex-shrink: 0;
		width: 100%;
		height: 54px;
	}

	&:hover {
		cursor: pointer;
	}
}

.logoWrapperMobile {
	height: 50px;
	width: fit-content;
	transform: translate(-50%, 0);
	position: absolute;
	left: 50%;
}

@media screen and (min-width: 951px) and (max-width: 1440px) {
	.wrapper {
		padding: 1rem 3.8rem;
	}
}

.foryouWrapper {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--main-bg);
	z-index: 111111111111111111111;

	@include media-query("mobile") {
		height: 54px;
	}
}

.headerButtonsWrapper {
	position: relative;
	@include flex-center();

	@include media-query("mobile") {
		position: absolute;
		right: 0;
	}
}

.localisationDropDown {
	position: absolute;
	top: 60px;

	padding: 10px 20px;
	border-radius: 8px;
	width: 108px;
	height: 0;
	background-color: var(--main-gray-hover);
	opacity: 0;
	transition: height .1s ease .1s, opacity .1s ease;
	z-index: 9999;

	&:dir(ltr) {
		left: 0;
	}
	&:dir(rtl) {
		left: 90px;
	}
	div {
		@include hover();

		font-size: 12px;
		font-weight: bold;
		padding: 10px 0;
		color: var(--secondary-gray);
		&:hover {
			color: var(--white);
			cursor: pointer;
		}
	}
}
.opened {
	transition: height .1s ease, opacity .1s ease .1s;
	height: 125px;
	opacity: 1;
	z-index: 99999;
}
.moreWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: var(--main-bg);

	@include media-query("mobile") {
		height: 54px;
		position: fixed;
		width: 100%;
		background: #111111;
		z-index: 99999999;
	}
}

.desctopFilterButton {
	background: var(--main-gray-hover);
	height: 50px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;

	@include flex-center();
	@include hover();

	&:hover {
		@include nav-button-hover();

		.filterIconPath {
			stroke: var(--white);
		}

		.closeIcon {
			stroke: var(--white);
		}
	}
}

.mobileFilterButton {
	height: 54px;
	width: 54px;
	z-index: 999999;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.mobileHeaderButtonCounter {
	position: absolute;
	left: -10px;
	top: 50%;
	transform: translateY(-55%);
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	color: var(--main-dark);
	font-weight: bold;
}

.mobileApplyFilterButton {
	height: 40px;
	width: 40px;
	z-index: 999999;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	background-color: var(--white);
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 4px;
	margin-right: 8px;

	span {
		position: absolute;
		width: 18px;
		height: 18px;
		border: 2px solid var(--white);
		color: var(--white);
		background-color: var(--main-dark);
		left: -6px;
		bottom: -6px;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50px;
		font-size: 10px;
		font-weight: bold;
	}

}

.mobileHeaderButttonCounter {
	position: absolute;
	left: -10px;
	background-color: var(--white);
	color: var(--main-dark);
	font-size: 10px;
	font-weight: bold;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.filterButton {
	background: var(--secondary-dark);
	width: 200px;
	height: 50px;
	@include flex-center();
	@include hover();

	&:hover {
		@include nav-button-hover();

		.filterIconPath {
			stroke: var(--hover-gray);
		}

		.closeIcon {
			stroke: var(--hover-gray);
		}
	}

	@include media-query("mobile") {
		width: 54px;
		height: 54px;
		position: absolute;
		z-index: 99999;
		right: 0;
	}
}

.localizationButton {
	font-size: 12px;
	font-weight: bold;
	color: var(--white);
	padding: 9px 10px;
	border-radius: 8px;
	@include flex-center();
	@include hover();

	&:hover {
		cursor: pointer;
		color: var(--main-button);
	}
}

.localizationButtonActive {
	background-color: var(--white);
	color: var(--main-button);
}

.headerProfile {
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	position: relative;
	margin-right: 40px;
	margin-left: 20px;
	&:hover {
		cursor: pointer;
	}

	img {
		position: relative;
		z-index: 100;
		width: 30px;
		height: 30px;
		border-radius: 50px;
	}
	span {
		font-weight: bold;
		font-size: 14px;
		text-transform: uppercase;
		color: var(--white);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 102;
	}
}
.headerProfileDrop {
	height: 30px;
	width: 30px;
	display: flex;
	align-items: center;
	position: relative;
	img {
		position: relative;
		z-index: 100;
		width: 30px;
		height: 30px;
		border-radius: 50px;
	}
	span {
		font-weight: bold;
		font-size: 14px;
		text-transform: uppercase;
		color: var(--white);
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 102;
	}
}

.activeHeaderProfile {
	position: absolute;
	width: 33px;
	height: 33px;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--acc-border);
	border-radius: 50%;
}

.logInButton {
	font-size: 12px;
	font-weight: bold;
	color: var(--white);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 50px;
	width: 100px;

	@include media-query("mobile") {
		display: none;
	}
	span {
		&:dir(ltr) {
			margin-left: 20px;
		}
		&:dir(rtl) {
			margin-right: 20px;
		}
		display: inline-block;

		@include text-8();
		@include hover();
	}

	&:hover {
		cursor: pointer;
		span {
			color: var(--main-button);
		}

		.loginIcon {
			fill: var(--hover-gray);
		}
	}
}

.backButton {
	&:dir(ltr) {
		margin-right: auto;
	}
	&:dir(rtl) {
		margin-left: auto;
	}

	background: var(--main-gray-hover);
	width: 200px;
	height: 50px;
	@include flex-center();
	@include hover();

	@include media-query("mobile") {
		position: absolute;
		width: 54px;
		height: 54px;
	}

	&:hover {
		@include nav-button-hover();

		.chevronLeft {
			stroke: var(--hover-gray);
		}
	}

	@include media-query("mobile") {
		@media (hover: none) {
			&:hover {
				background-color: initial;
				color: initial;
			}
		}
	}
}

.mobileBack {
	background: transparent;
	left: 0;
}

.chevronLeft,
.closeIcon,
.filterIconPath,
.loginIcon {
	@include hover();
}

.accountDropDown {
	min-width: 210px;
	position: absolute;
	top: 60px;
	right: 35px;
	padding: 20px 20px 15px 20px;
	border-radius: 8px;
	height: 0;
	background-color: var(--main-gray-hover);
	opacity: 0;
	transition: height .1s ease .1s, opacity .1s ease;
	overflow: hidden;

	&:dir(ltr) {
		right: 35px;
	}
	&:dir(rtl) {
		right: -100px;
	}

}
.openedAcc {
	transition: height .1s ease, opacity .1s ease .1s;
	height: 305px;
	opacity: 1;
	z-index: 99999;
}

.accDropSectionWrapper {
	border-bottom: 1px solid var(--secondary-border);
}

.accDropName {
	font-size: 14px;
	font-weight: bold;
	color: var(--white);
}

.accId {
	font-size: 12px;
	font-weight: bold;
	color: var(--secondary-gray);
	margin-bottom: 15px;
	padding: 10px 0;
	border-bottom: 1px solid var(--secondary-border);
}

.accDropMenu {
	font-size: 12px;
	font-weight: bold;
	color: var(--border-gray);
	padding: 5px 0;
	margin-bottom: 10px;

	@include hover();

	&:hover {
		color: var(--white);
		cursor: pointer;
	}

	&:last-child {
		margin-bottom: 15px;
	}
}

.accDropMiddleSectionWrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 20px 0;
	border-bottom: 1px solid var(--secondary-border);

	svg {
		margin-right: 10px;
	}

	&:hover {
		cursor: pointer;
	}
}

.accDropPrepay {
	font-size: 14px;
	margin-bottom: 4px;
	color: var(--text-gray-1);
}

.accDropProceed {
	font-size: 14px;
	font-weight: bold;
	color: var(--white);
}

.signOut {
	font-size: 12px;
	font-weight: bold;
	color: var(--border-gray);
	padding: 5px 0;
	margin-top: 15px;

	@include hover();

	&:hover {
		cursor: pointer;
		color: var(--white);
	}
}


.userName {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	padding-bottom: 20px;
	border-bottom: 1px solid var(--secondary-border);
}

.plans {
	border-bottom: 1px solid var(--secondary-border);
	font-weight: bold;
	padding: 20px 0;
	font-size: 12px;
	color: var(--text-gray-1);
	display: flex;
	align-items: center;
	justify-content: flex-start;
	gap: 10px;
	@include hover();

	&:hover {
		cursor: pointer;
		color: var(--white);
	}
}
