@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');
@import "./styles/mixins";

html[dir="rtl"] {
	//direction: rtl;
	text-align: right;
}

html[dir="ltr"] {
	//direction: ltr;
	text-align: left;
}

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
  'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
  'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --main-dark: #000000;
  --main-bg: #111111;
	--modal-bg: rgba(17, 17, 17, 0.9);
	--main-purple: #E50914;
	--main-green: #00AAAA;
	--main-orange: #EF3500;
  --main-gray-hover: #222222;

	--secondary-green: #2EBD85;
  --secondary-dark: #1B1B1B;
  --secondary-gray: #888888;

  --main-button: #E50914;
  --grey-button: #545253;

  --hover-red: #B70808;
  --hover-gray: #CACAD0;
	--hover-gray-nav: #555555;

	--acc-border: #00AAAA;
  --text-gray: #444444;
  --text-gray-1: #A7A7A7;
  --border-gray: #888888;
  --secondary-border: #333333;
	--outlined-border: rgba(250, 250, 250, 0.3);
	--scroll: #777777;
	--gray: #98989E;
  --white: #FAFAFA;
	--white-hover: #F0F0F0;
	--avatarBg: #252525;
	--white-opacity: rgba(250, 250, 250, 0.67);

  --fz-1: 40px;
  --lh-1: 48px;

  --fz-2: 30px;
  --lh-2: 36px;

  --fz-3: 20px;
  --lh-3: 24px;

  --fz-4: 18px;
  --lh-4: 22px;

  --fz-5: 16px;
  --lh-5: 20px;

  --fz-6: 14px;
  --lh-6: 21px;

  --fz-7: 12px;
  --lh-7: 15px;

  --fz-8: 10px;
  --lh-8: 15px;

	--fz-9: 24px;
	--lh-9: 28px;
  --overlay-black-80: #00000080;
  --overlay-white-26: #FFFFFF26;

  --br-5: 5px;
  --br-7: 7px;
	--br-8: 8px;
	--br-10: 10px;
  --br-12: 12px;
	--br-15: 15px;
	--br-20: 20px;
  --br-30: 30px;
  --br-60: 60px;
}

* {
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+/Edge */
  user-select: none; /* Standard syntax */
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

.fp-watermark {
  display: none;
}

html,
body {
  max-width: 100vw;
	height: 100%;
  //overflow-x: hidden;
  background-color: var(--main-dark);
  color: var(--secondary-gray);
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  @include media-query('mobile') {
    background: var(--main-dark);
  }
}

#root {
	height: 100%;
}

ul {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.MuiSlider-thumb{
  color: var(--white);
  width: 15px !important;
  height: 15px !important;
}

.progress-controller-mobile .MuiSlider-thumb,
.progress-controller .MuiSlider-thumb{
  display: none !important;
}

.period-controller .MuiSlider-rail{
	color: var(--secondary-border);
	opacity: 1;
  height: 1px !important;
}

.period-controller .MuiSlider-track{
  height: 1px !important;
}

.period-controller .MuiSlider-thumb{
  height: 15px !important;
  width: 15px !important;
}

.progress-controller-mobile .MuiSlider-rail {
  height: 13px !important;
  border-radius: 0 !important;
  color: rgba(255, 255, 255, 0.7);
  transition: all 0.5s;
}

.progress-controller-mobile .MuiSlider-track {
  height: 13px !important;
  border-radius: 0 !important;
  transition: all 0.5s;
}

.MuiSlider-root {
  @include media-query('mobile') {
		padding: 20px 0 20px 0 !important;
	}
}

.MuiSlider-track{
  color: var(--main-purple);
}
.MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
  box-shadow:none !important;
}

.progress-controller-desktop .MuiSlider-rail {
  height: 60px !important;
  border-radius: 8px !important;
  color: rgba(30, 30, 30, 0.7);
  transition: all 0.5s;
  opacity: 1 !important;
}

.progress-controller-desktop .MuiSlider-track {
  height: 60px !important;
  border: none;
  border-radius: 8px 0 0 8px!important;
  transition: all 0.5s;
  min-width: 8px !important;
  color: rgba(229, 9, 20, 0.8);
}

.progress-controller-desktop-opacity .MuiSlider-rail {
  border-radius: 8px 8px 8px 8px!important;
}

.progress-controller-desktop .MuiSlider-thumb{
  display: none !important;
}

.MuiPaper-root .css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
	transition: all .2s ease-in-out;
	background-color: var(--main-bg);
	border-radius: 0;
}

.MuiPaper-root {
	background-color: var(--main-bg) !important;
	height: 150px !important;
}

.MuiPaper-root .css-6hp17o-MuiList-root-MuiMenu-list {
	transition: all .2s ease-in-out;
	background-color: var(--main-bg);
	color: var(--white);
	border: none;
	border-radius: 0;
}

.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
	transition: all .2s ease-in-out;
	padding: 14px 20px !important;
	min-height: auto !important;
}
.MuiFormControl-root .css-sblxtm-MuiFormControl-root {
		transition: all .2s ease-in-out;
		margin: 0 !important;
}

.MuiList-root li {
		transition: all .2s ease-in-out;
		font-size: 14px !important;
}

input, textarea, select {
	font-size: 16px;
}
