.inputWrapper {
	width: 100%;
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	padding-top: 20px;

	input {
		font-size: 14px;
	}
}

.errorMsg {
	margin-bottom: 5px;
	font-size: 10px;
	color: var(--main-button);
	position: absolute;
	top: 0;
	left: 0;
}
