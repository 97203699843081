@import "../../../styles/mixins";

$transition-duration: 0.3s;

.adSkip {
	position: absolute;
	bottom: 100px;
	z-index: 1111111111111;
	right: 50px;
	padding: 21px 30px;
	border-radius: 15px;
	color: white;
	font-weight: bold;
	font-size: 14px;
	transition: all $transition-duration ease;
	border: none;
	outline: none;

	@include media-query('mobile') {
		padding: 15px 30px;
		right: 10px;
		bottom: 210px;
	}
}

.disabled {
	background-color: rgba(34, 34, 34, 0.8);
	cursor: not-allowed;
}

.enabled {
	background-color: rgba(34, 34, 34, 0.8);
	cursor: pointer;

	&:hover {
		background-color: rgba(34, 34, 34, 0.9);
	}
}
