.desctopPlayerControllWrapper {
    width: 100%;
    position: absolute;
    bottom: 120px;
    left: 0;
    z-index: 99999999999;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all .3s ease-in-out;
}

.sliderWrapper {
    width: 600px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.volumeIconWrapper {
    position: absolute;
    right: 30px;
    top: 8px;
}

.volumeIconWrapper:hover {
    cursor: pointer;
}

.playPauseWrapper {
    width: 100%;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.playPauseWrapperVolumeDuration {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.progresTimeWrapper {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: var(--white);
    padding: 0 0 2px 0;
    position: absolute;
    top: 50%;
    left: 30px;
		transform: translateY(-40%);
}

.progressWrapper {
    width: 100%;
}

@media screen and (min-width: 1680px) {
    .desctopPlayerControllWrapper {
        bottom: 140px;
    }
}
