@import "../../styles/mixins";

.menu {
	min-width: 87px;
	display: flex;
	flex-direction: column;
	align-items: center;
}
.wrapper {
    position: fixed;
    left: 2.2rem;
    top: 0;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    z-index: 99;
    padding-top: 150px;
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
		@include hover();

		&:hover {
			cursor: pointer;
			color: var(--hover-gray);

			.profileIcon,
			.searchIcon,
			.forYouIcon {
				stroke: var(--hover-gray);
			}

			.chooseIcon,
			.inviteIcon {
				fill: var(--hover-gray);
			}
		}
}

.itemSemanticWrapper {
    margin-bottom: 1.8rem;
}

ul li.itemSemanticWrapper:last-child {
    margin-bottom: 0;
}

.itemTitle {
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}

.itemTitleActive {
    color: var(--white);
}

.icon {
    margin-bottom: 2px;
}

.topSidebarWrapper {
    margin-bottom: 100px;
}

.inviteIcon,
.profileIcon,
.chooseIcon,
.searchIcon,
.forYouIcon {
	@include hover();
}

.inviteIconWrapper {
	position: relative;
}

.inviteIconPercent {
	position: absolute;
	top: 3px;
	right: -7px;
}
