@import "../../../styles/mixins";

.wrapper {
	min-height: 100%;
	background: var(--main-bg);
}

.headerWrapper {
	justify-content: space-between;
}

.backButton {
	@include media-query("mobile") {
		display: none;
	}
}

.container {
	height: 100%;
	padding: 150px 270px 111px 270px;
	background: var(--main-bg);

	@include media-query("mobile") {
		padding: 94px 16px 111px 16px;
	}
}

.contentContainer {
	@include flex-column(80px);
	justify-content: center;
	align-items: center;

	@include media-query("mobile") {
		gap: 30px;
	}
}
