.successToast {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: var(--main-gray-hover);
	color: var(--white);
	padding: 20px 30px;
	border-radius: 8px;
	opacity: 0;
	transition: opacity 0.2s ease-in-out, z-index 0.4s;
	display: flex;
	align-items: center;
	justify-content: center;
  z-index: -1;
	svg {
		margin-right: 20px;
	}

	span {
		font-size: 20px;
	}
}

.successToast.show {
	opacity: 1;
	z-index: 99999;
}
