@mixin padding($top: null, $right: null, $bottom: null, $left: null) {
  padding: $top $right $bottom $left;
}

@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin flex-column($gap: 20px) {
  display: flex;
  flex-direction: column;
  gap: $gap;
}

@mixin flex-row($gap: 20px) {
  display: flex;
  flex-direction: row;
  gap: $gap;
}

@mixin badge-sm($gap: 20px) {
  padding: 2px 7px;
  @include text-8();
  @include fs-normal();
  background-color: var(--main-purple);
  border-radius: var(--br-60);
}

@mixin badge-md() {
  padding: 3px 7px;
  @include text-7();
  @include fs-bold();
  background-color: var(--main-purple);
	border-radius: var(--br-60);
}

@mixin badge-lg($gap: 20px) {
  padding: 4px 10px;
  @include text-7();
  @include fs-bold();
  background-color: var(--main-purple);
	border-radius: var(--br-60);
}

@mixin width-full($max-width: null) {
  max-width: $max-width;
  width: 100%;
}

@mixin fs-normal($color: var(--text-gray-1), $weight: 400, $style: normal) {
  color: $color;
  font-weight: $weight;
  font-style: $style;
}

@mixin fs-bold($color: var(--white), $weight: 700, $style: bold) {
  color: $color;
  font-weight: $weight;
  font-style: $style;
}

@mixin text-1($size: var(--fz-1), $line-height: var(--lh-1)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin text-2($size: var(--fz-2), $line-height: var(--lh-2)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin text-3($size: var(--fz-3), $line-height: var(--lh-3)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin text-9($size: var(--fz-9), $line-height: var(--lh-9)) {
	font-size: $size;
	line-height: $line-height;
}

@mixin text-4($size: var(--fz-4), $line-height: var(--lh-4)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin text-5($size: var(--fz-5), $line-height: var(--lh-5)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin text-6($size: var(--fz-6), $line-height: var(--lh-6)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin text-7($size: var(--fz-7), $line-height: var(--lh-7)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin text-8($size: var(--fz-8), $line-height: var(--lh-8)) {
  font-size: $size;
  line-height: $line-height;
}

@mixin hover() {
  transition: all .2s ease-in-out;
}

@mixin nav-button-hover() {
  cursor: pointer;
  background: var(--text-gray);
}

@mixin media-query($media-type) {
  @if $media-type == "mobile" {
    @media (max-width: 1023px) {
      @content;
    }
  } @else if $media-type == "tablet" {
    @media (min-width: 768px) and (max-width: 1023px) {
      @content;
    }
  } @else if $media-type == "desktopM" {
		@media (min-width: 1023px) and (max-width: 1500px){
			@content;
		}
	} @else if $media-type == "desktopL" {
    @media (min-width: 1024px) {
      @content;
    }
  } @else if $media-type == "desktopXL" {
    @media (min-width: 2050px) {
      @content;
    }
  } @else if $media-type == "desktopXXL" {
		@media (min-width: 2560px) {
			@content;
		}
	}
}
