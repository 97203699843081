@import "../../styles/mixins";

.preLoginWrapper {
	min-height: 100vh;
	background-color: var(--main-bg);
	padding-top: 150px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-bottom: 80px;

	@include media-query("mobile") {
		padding: 95px 0;
	}
}

.contentWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	padding: 0 15px;

	.mainContent {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		padding-bottom: 30px;
		border-bottom: 1px solid var(--secondary-border);

		svg {
			margin-bottom: 40px;
		}

		.title {
			font-size: 20px;
			color: var(--white);
			margin-bottom: 20px;
		}

		.descr {
			font-size: 14px;
			color: var(--text-gray-1);
			margin-bottom: 30px;
			text-align: center;
		}

		.btn {
			height: 48px;
			width: 200px;
		}
	}

}
