@import "../../styles/mixins";

.discountPageWrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	background-image: url("../../static/imgs/bg.jpg");
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;

	@include media-query('mobile') {
		background-image: url("../../static/imgs/mobileBg.jpg");
		background-size: cover;
		justify-content: flex-start;
	}
}

.iconWrapper {
	margin-bottom: 40px;
	svg {
		width: 40px;
	}

	@include media-query('mobile') {
		margin-bottom: 20px;
	}
}
.discountTitle {
	font-size: 20px;
	margin-bottom: 30px;
	color: var(--white);

	@include media-query('mobile') {
		margin-bottom: 20px;
	}
}
.discountDescription {
	font-size: 14px;
	color: var(--text-gray-1);
	margin-bottom: 70px;

	@include media-query('mobile') {
		margin-bottom: 40px;
	}
}

.cards {
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-query('mobile') {
		flex-direction: column;
	}
}

.cardWrapper {
	//height: 323px;
	min-height: 323px;
	border-radius: 8px;
	border: 1px solid var(--secondary-border);
	padding: 20px;
	width: 270px;


	&:dir(ltr) {
		margin-right: 20px;
	}
	&:dir(rtl) {
		margin-left: 20px;
	}

	&:first-child {
		.cardButton {
			margin-top: 35px;
		}
	}
	&:last-child {

		&:dir(ltr) {
			margin-right: 0;
		}
		&:dir(rtl) {
			margin-left: 0;
		}
	}

	@include media-query('mobile') {
		margin-right: 0;
		margin-bottom: 20px;
	}
}

.cardCosts {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 15px;

	font-size: 20px;
	color: var(--white);
	font-weight: 600;
	.costCurrency {
		font-weight: 400;
	}
}

.cardContinue {
	font-size: 12px;
	color: var(--text-gray-1);
	padding-bottom: 30px;
	margin-bottom: 30px;
	border-bottom: 1px solid var(--secondary-border);
}

.cardButton {
	height: 48px;
	width: 100%;
	background-color: var(--main-button);
	color: var(--white);
	border-radius: 8px;
	font-size: 14px;

	@include flex-center();
	@include hover();
	margin-top: auto;

	&:hover {
		cursor: pointer;
		background-color: var(--hover-red);
	}

	@include media-query('mobile') {
		&:hover {
			cursor: default;
			background-color: var(--main-button);
		}
	}
}

.cardPointsList {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: space-between;
}

.planPointItem {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 14px;
	font-weight: bold;
	color: var(--white);
	margin-bottom: 20px;

	&:dir(ltr) {
		span {
			margin-left: 10px;
		}
	}
	&:dir(rtl) {
		span {
			margin-right: 10px;
		}
	}



	&:last-child {
		margin-bottom: 30px;
	}
}
.headerDiscount {
	position: static;
	width: 100%;
}

.discountContent {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	overflow-y: scroll;

	@include media-query('mobile') {
		padding-bottom: 100px;
		margin-top: 40px;
	}
}

.modalContent {
	padding: 20px 30px;
	border-radius: 10px;
	background-color: var(--main-gray-hover);
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-query('mobile') {
		max-width: 390px;
		flex-direction: column;
		margin: 0 15px;
	}

	.textWrapper {
		margin-right: 25px;
		padding-right: 30px;
		border-right: 1px solid var(--secondary-border);

		@include media-query('mobile') {
			margin-right: 0;
			padding-right: 0;
			border-right: none;
			margin-bottom: 30px;
		}
	}

	.modalTitle {
		font-size: 14px;
		color: var(--text-gray-1);
		margin-bottom: 10px;
	}
	.modalDescription {
		font-size: 24px;
		color: var(--white);

		@include media-query('mobile') {
			font-size: 18px;
		}
	}

	svg:hover {
		cursor: pointer;
	}
}

@media (max-height: 900px) {

	.fourthText,
	.thirdText,
	.secondText {
		margin-bottom: 50px;
	}
}

@media (max-height: 800px) {

	.fourthText,
	.thirdText,
	.secondText {
		margin-bottom: 40px;
	}
}
