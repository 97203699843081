@import "../../../../styles/mixins";

.filtersHeader {
	position: absolute;
	width: 800px;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	border-radius: 8px;
	z-index: 22222222222222;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	font-weight: bold;
	color: var(--text-gray-1)
}

.filtersHeaderLeftContent {
	padding: 13px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		display: inline-block;
		margin-left: 14px;
		color: var(--white);
		font-size: 10px;
	}
}
.iconHashWrapper {
	display: flex;
	margin-right: 5px;
}
.filtersHeaderRightContent {
	padding: 13px 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		display: inline-block;
		margin-left: 17px;
	}
	@include hover();

	&:hover {
		cursor: pointer;
		color: var(--white);
		.iconClass {
			stroke: var(--white);
		}
	}
}

.filterButtonWrapper {
	width: 50px;
	height: 50px;
	background-color: var(--secondary-border);
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: fixed;

	right: 6%;
	top: 22.5%;

	@include hover();

	svg {
		padding-top: 1px;
	}

	&:hover {
		background-color: var(--hover-gray-nav);
		cursor: pointer;
		color: var(--white);
		.iconClass {
			stroke: var(--white);
		}
	}

	.filtersButtonCounter {
		position: absolute;
		width: 16px;
		height: 16px;
		background-color: var(--white);
		border-radius: 50%;
		bottom: 0;
		right: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 10px;
		font-weight: bold;
		color: var(--main-dark);
	}
}

.filterButtonWrapperActive {
	.iconClass {
		stroke: var(--white);
	}
}
.filterCounter {
	margin-right: 15px;
	width: 20px;
	height: 20px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: var(--secondary-border);
	font-size: 10px;
}
.iconClass {
	@include hover();
}

.filtersLeftContentList {
	display: flex;
	align-items: center;
	span {
		display: inline-block;
		margin: 0 10px;
		color: var(--text-gray-1)
	}

	.allFilters {
		color: var(--white);
	}
}
