@import "../../styles/mixins";

.headerWrapper {
	justify-content: space-between;
}

.notFoundWrapper {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: var(--main-bg);
	svg {
		margin-bottom: 30px;
	}
	div {
		font-size: 20px;
		color: var(--secondary-border);
	}
}
.unautorisedWrapper {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background: var(--main-bg);

	.unautorisedTitle {
		font-size: 30px;
		color: var(--white);
		margin-bottom: 30px;

		@include media-query("mobile") {
			margin-bottom: 20px;
			font-size: 20px;
		}
	}
	.unautorisedText {
		font-size: 14px;
		color: var(--text-gray-1);
		margin-bottom: 30px;
		@include media-query("mobile") {
			margin-bottom: 20px;
		}
	}

	.unautorisedBtn {
		width: 200px;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		color: var(--white);
		padding: 15px 32px;
		background-color: var(--main-purple);
		border-radius: 8px;
		border: none;
		@include flex-center();
		transition: all 0.2s ease-in-out;
		@include media-query("mobile") {
			@include text-7();
		}

		&:hover {
			cursor: pointer;
			background-color: var(--hover-red);
		}
	}

	svg {
		margin-bottom: 70px;

		@include media-query("mobile") {
			margin-bottom: 30px;
		}
	}
}
.topControlsWrapper {
	@include flex-column(20px);
}

.searchFieldWrapper {
	margin-top: 64px;
	margin-bottom: 60px;
	padding: 12px 20px;
	border: 1px solid var(--secondary-border);
	border-radius: 8px;
	@include flex-row(10px);
	align-items: center;

	@include media-query("mobile") {
		@include flex-row(10px);
	}

	svg {
		display: flex;
		flex-shrink: 0;
	}
}
.focused {
	border: 1px solid var(--hover-gray-nav);
}

.input {
	&:placeholder-shown ~ .label {
		@include text-6();
		cursor: text;
		top: 20px;
	}
	&::placeholder {
		color: var(--text-gray-1) !important;
	}

	&:focus {
		&::placeholder {
			color: var(--text-gray) !important;
		}
	}
}

.label {
	@include text-8();
}


.container {
	@include flex-row(100px);
	justify-content: space-between;
	padding: 150px 100px 80px 206px;
	background: var(--main-bg);

	@include media-query("mobile") {
		padding: 85px 15px;
		height: 100vh;
	}
}

.leftColumnWrapper {
	@include flex-column(70px);
	justify-content: space-between;
	width: 100%;

	@include media-query("mobile") {
		@include flex-column(30px);
	}
}

.leftColumnTopContent {
	@include flex-column(40px);

	@include media-query("mobile") {
		@include flex-column(30px);
	}
}

.contentWrapper {
	@include width-full(100%);
	@include flex-column(0);
	height: 100%;
	padding: 0 15px;
	max-width: 330px;
	box-sizing: border-box;
}

.chipsButton {
	@include flex-center();
	@include text-8();
	@include fs-normal(var(text-gray-1));
	@include hover();
	font-weight: bold;
	border-radius: var(--br-5);
	border: 1px solid #333333;
	width: 116px;
	height: 30px;
	gap: 5px;

	&:hover {
		border: 1px solid white;
		color: var(--white);
	}

	@include media-query("mobile") {
		flex-shrink: 0;
		width: auto;
		height: auto;
		padding: 8px 16px;
	}
}

.chipsButtonActive {
	border: 1px solid white;
	color: var(--white);
}

.chipsListWrapper {
	@include flex-row(10px);
	align-items: center;
	justify-content: flex-start;

	@include media-query("mobile") {
		overflow-x: scroll;
	}
}

.form__group {
	padding-top: 0;
}

.advertisement {
	width: 100%;
	height: 350px;
	border-radius: var(--br-20);
	margin-top: 50px;
}
