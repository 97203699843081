@import "../../../styles/mixins";

.footerWrapperMobile {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.8rem 4.3rem;
	background-color: var(--main-dark);
	z-index: 99999999999;
	color: var(--secondary-gray);
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-transform: uppercase;
}

.footerWrapperMobile {
	padding: 15px 20px 10px 20px;
	justify-content: space-between;
}

.footerWrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 18px 50px;
	background-color: var(--main-dark);
	z-index: 99999999999;
	color: var(--secondary-gray);
	border-top: 1px solid var(--main-bg);

	.links {
		@include text-8();
		margin-left: 45px;

		a,
		span {
			@include hover();
			display: inline-block;
			margin: 0 15px;

			&:hover {
				cursor: pointer;
				color: var(--white);
			}
		}
	}

	.copy {
		@include hover();
		@include text-8();
		margin-left: auto;

		span {
			font-weight: bold;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.footerModalWrapper {
	position: static;
}
