@import "../../../../styles/mixins";

.wrapper {
  @include flex-column(30px);

  @include media-query("mobile") {
    @include flex-column(30px);
  }
}

.imagesWrapper {
  @include flex-row();

  @include media-query("mobile") {
    width: 100%;
    object-fit: cover;
    @include flex-column();
  }

  & > img {
    height: 242px;
    width: auto;

    @include media-query("mobile") {
      width: 100%;
      height: auto;
    }
  }
}

.rightColumn {
  @include flex-column();

  @include media-query("mobile") {
    @include flex-row();

    & > img {
      object-fit: cover;

      @include media-query("mobile") {
        width: 100%;
        max-width: calc(100% - 50% - 10px);
      }
    }
  }
}

.container {
  position: relative;
  height: 111px;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #00000090;
    z-index: 999;
  }

  @include media-query("mobile") {
    width: 100%;
  }
}

.image {
	display: inline-block;
	width: 240px !important;
	height: 140px !important;
  border-radius: var(--br-8);
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	position: relative;
	@include hover();

	&:before {
		@include hover();
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(17, 17, 17, 0);
		border-radius: 8px;
	}

	@include media-query("mobile") {
		width: 100%;
		height: 100%;
	}

  @include media-query("mobile") {
    width: 163px;
    object-fit: cover;
  }
}
.pathClass{
	@include hover();
}
.imgWrapper {
	position: relative;
	padding: 10px 10px 7px 10px;
	border-radius: var(--br-8);
	border: 1px solid var(--secondary-border);
	@include hover();

	&:hover {
		cursor: pointer;
		.image:before {
			background-color: rgba(17, 17, 17, 0.3);
		}
		.pathClass {
			fill-opacity: 0.3;
			fill: var(--white);
		}
		border: 1px solid var(--main-button);
	}

	@include media-query('mobile') {
		&:hover {
			cursor: default;
			.image:before {
				background-color: rgba(17, 17, 17, 0);
			}
			border: 1px solid var(--secondary-border);
		}
	}
}
.playIcon {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 999999999;
}

.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  @include text-2();
  @include fs-normal(var(--white));

  @include media-query("mobile") {
    z-index: 9999;
  }
}

.cardWrapper {
	@include flex-row(15px);
	max-width: 100%;
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	padding-right: 10px;
	padding-bottom: 3px;
}

.cardWrapper::-webkit-scrollbar {
	transition: all 0.5s ease-in-out;
	height: 5px;
	margin-left: 20px;
}

.cardWrapper::-webkit-scrollbar-track {
	height: 0;
	width: 0;
}

.cardWrapper::-webkit-scrollbar-thumb {
	background-color: var(--scroll);
	border-radius: var(--br-30);
}

.cardWrapperRev {
	@include flex-row(15px);
	max-width: 100%;
	width: 100%;
	overflow-x: scroll;
	overflow-y: hidden;
	padding-right: 10px;
}

.cardWrapperRev::-webkit-scrollbar {
	transition: all 0.5s ease-in-out;
	height: 6px;
	margin-left: 20px;
}

.cardWrapperRev::-webkit-scrollbar-track {
	height: 0;
	width: 0;
}

.cardWrapperRev::-webkit-scrollbar-thumb {
	background-color: var(--scroll);
	border-radius: var(--br-30);
}

svg {
	@include hover();
}
.addReviewButton {
	width: 168px;
	min-width: 168px;
	height: 168px;
	border-radius: var(--br-8);
	border: 1px dashed var(--secondary-border);
	display: flex;
	align-items: center;
	justify-content: center;
	@include hover();

	&:hover {
		cursor: pointer;
		border: 1px dashed var(--hover-gray-nav);
		svg {
			rect {
				stroke: var(--hover-gray-nav);
			}
			path {
				fill: var(--hover-gray-nav);
			}
		}
	}
}
.addReviewButtonMobile {
	width: 100%;
	height: 100px;
	border-radius: var(--br-8);
	border: 1px dashed var(--secondary-border);
	@include hover();
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		cursor: pointer;
		border: 1px dashed var(--hover-gray-nav);
		svg {
			rect {
				stroke: var(--hover-gray-nav);
			}
			path {
				fill: var(--hover-gray-nav);
			}
		}
	}
}
.reviewCard {
	max-height: 168px;
	min-width: 490px;
	padding: 20px;
	border-radius: var(--br-8);
	border: 1px solid var(--secondary-border);
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	@include media-query("mobile") {
		min-width: 300px;
		max-width: 300px;
		max-height: 210px;
	}

	@include hover();
	&:hover {
		cursor: pointer;
		background-color: var(--white-hover);
		border: 1px solid var(--white-hover);
		.reviewText {
			color: var(--main-dark);
		}
		.authorName {
			color: var(--main-dark);
		}
		.reviewVotes {
			svg	{
				fill: var(--white);
				path: var(--white);
				path {
					fill: var(--white);
				}
			}
			span {
				color: var(--white);
			}
		}
	}

	@include media-query("mobile") {
		&:hover {
			cursor: default;
			background-color: inherit;
			border: 1px solid var(--secondary-border);
			.reviewText {
				color: var(--text-gray-1);
			}
			.authorName {
				color: var(--white);
			}
			.reviewVotes {
				svg	{
					fill: var(--main-dark);
					path: var(--main-dark);
					path {
						fill: var(--main-dark);
					}
				}
				span {
					color: var(--main-dark);
				}
			}
		}
	}
}

.reviewText {
	font-size: 14px;
	font-weight: normal;
	line-height: 150%;
	color: var(--text-gray-1);

	@include media-query("mobile") {
		margin-bottom: 15px;
	}
}

.authorWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.reviewAuthor {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	.av {
		text-transform: capitalize;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		margin-right: 20px;
		background-image: url("../../../../components/icons/details/av.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		font-size: 18px;
		font-weight: bold;
		color: var(--white);

		display: flex;
		align-items: center;
		justify-content: center;
	}

	.authorName {
		font-size: 14px;
		color: var(--white);
	}
}

.hoveredRevModal {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 999;
	@include hover();
}
.hoveredRev {
	opacity: 0;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 999;
	@include hover();
}

.likedRevModal {
	path {
		fill: var(--white);
	}
	opacity: 1;
}
.likedRev {
	path {
		fill: var(--white);
	}
	opacity: 1;
}

.reviewVotes {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	svg {
		margin-right: 10px;
	}
	span {
		font-size: 12px;
		color: var(--white);
	}

	&:hover {
		.likedRevModal {
			path {
				fill: var(--white);
			}
		}
		.likedRev {
			path {
				fill: var(--main-dark);
			}
		}
		.hoveredRev {
			opacity: 1;
		}
		.hoveredRevModal {
			opacity: 1;
		}
		cursor: pointer;
	}
}

.reviewModalContentWrapper {
	position: relative;
	padding: 100px 50px 10px 50px;
	display: flex;
	gap: 35px;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;

	@include media-query('mobile') {
		padding: 100px 20px 10px 20px;

		.reviewModalContentDecor {
			position: absolute;
			top: 8px;
			left: 50%;
			transform: translateX(-50%);
			width: 130px;
			height: 5px;
			border-radius: 100px;
			background-color: var(--hover-gray-nav);
		}
	}


}
