.footerContentDataMobile {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 100%;
    position: relative;
    z-index: 100000000;
}

.footerContentDataYear {
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    color: var(--text-gray);
}

.footerContentDataCtr {
    color: var(--text-gray);
    font-size: 12px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    margin-left: 20px;
    text-transform: uppercase;
}

.footerContentDataNumber {
    color: #1C1C1C;
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    line-height: 60.51px;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
}

.cover {
    position: absolute;
    top: 20px;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 1111111111;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px 60px;

		.loaderBg {
			position: absolute;
			top: 20px;
			bottom: 20px;
			left: -10px;
			right: -10px;
			background-color: var(--main-dark);
			display: flex;
			align-items: center;
			justify-content: center;
		}
}

.bgCover {
    position: absolute;
    top: 19%;
    bottom: 20%;
    left: 0;
    right: 0;
    z-index: 99997;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
.bgCover:before {
    content: '';
    position: absolute;
    top: -1px;
    bottom: -1px;
    left: 0;
    right: 0;
    background: linear-gradient(0deg, rgba(29, 29, 32, 0.80) 0%, rgba(29, 29, 32, 0.80) 100%);
}
.mobileBottomMenu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    width: 100%;
    margin-bottom: 13px;
}


.footerContentButtonMobile {
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color: var(--white);
    padding: 15px 59px;
    background-color: var(--main-purple);
    border-radius: 8px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footerContentButtonMobile {
    padding: 17px 45px;
}

.footerContentButtonMobile:hover {
    background-color: var(--hover-red);
}

.progressControllerWrapper {
    margin: 0;
    width: 100%;
    position: relative;
    transform: translateY(2px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.progressControllerWrapperMobile {
    margin: 0;
    padding: 0;
}

/* Enter start state */
.fadeEnter {
	transform: scale(0.1);
	opacity: 0;
}

/* Enter active state, use the 'divAnimation' keyframes */
.fadeEnterActive {
	animation: divAnimation 0.4s ease-out forwards;
}

/* Exit start state, technically not needed here as the exit animation starts from the component's current state */
.fadeExit {
	/* No initial styles required, the animation uses the component's current state */
}

/* Exit active state, use the 'divDisappear' keyframes */
.fadeExitActive {
	animation: divDisappear 0.4s ease-out forwards;
}
@keyframes divAnimation {
	0% {
		transform: scale(0.1);
		opacity: 0;
	}
	60% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes divDisappear {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	40% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		transform: scale(0.1);
		opacity: 0;
	}
}
