@import "../../../styles/mixins";

.cardWrapper {
  @include flex-row();
  align-items: center;
  width: 100%;
	height: 186px;
	border-radius: var(--br-8);


	&:dir(ltr) {
		padding: 10px 35px 10px 10px;
	}
	&:dir(rtl) {
		padding: 10px 10px 10px 35px;
	}
	border: 1px solid var(--secondary-border);
	position: relative;
	@include hover();
	&:hover {
		border: 1px solid var(--main-button);
		cursor: pointer;
	}

	@include media-query("mobile") {
		&:hover {
			cursor: default;
			border: 1px solid var(--secondary-border);
		}
	}
}

.imgWrapper {
	position: relative;
	max-height: 200px;
	max-width: 130px;
	height: 100%;
	width: 100%;

	&:before {
		@include hover();
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(17, 17, 17, 0);
		border-radius: 8px;
	}
	@include hover();

	&:hover {
		cursor: pointer;

		&:before {
			background-color: rgba(17, 17, 17, 0.3);
		}
		.hoverPlayIcon {
			opacity: 1;
			@include hover();
			.wrapperPath {
				@include hover();
				fill-opacity: 0.3;
			}
		}
	}
	@include media-query("mobile") {
		&:hover {
			cursor: default;
			&:before {
				background-color: rgba(17, 17, 17, 0);
			}
			.hoverPlayIcon {
				opacity: 0;
			}
		}
	}
}

.hoverPlayIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity 0.2s ease-in;
}
.image {
  border-radius: var(--br-8);
  object-fit: cover;
  max-height: 200px;
  max-width: 130px;
  height: 100%;
  width: 100%;
}

.textWrapper {
  @include flex-column(0);
  width: 100%;
}

.savedIconWrapper {
	position: absolute;
	top: 20px;


	&:dir(ltr) {
		right: 20px;
	}
	&:dir(rtl) {
		left: 20px;
	}
}

.savedIcon {
	@include hover();
  &:hover {
    fill: var(--white);
    cursor: pointer;
  }

	@include media-query("mobile") {
		&:hover {
			fill: transparent;
			cursor: default;
		}
	}
}

.movieTitle {
	margin-bottom: 19px;
  @include text-5();
  @include fs-normal(var(--white));
}

.infoWrapper {
  @include flex-column(8px);
}

.rowInfoWrapper {
  @include flex-row(14px);
	align-items: center;
  @include text-8();
  @include fs-normal();
}

.badge {
  @include badge-md();
  @include fs-normal(var(--white));
	@include text-8();
	line-height: 100%;
	border-radius: 3px;
}

.country {
	@include text-8();
  @include fs-bold();
}

.ratingWrapper {
	width: fit-content;
  @include flex-row(5px);
	align-items: center;
	justify-content: center;
	margin-bottom: 19px;
	padding: 4px 7px;
	border-radius: 5px;
	border: 1px solid var(--outlined-border);
}

.rating {
	padding-top: 1px;
	@include text-7();
  @include fs-bold();
	line-height: 100%;
}

.ratingName {
	@include text-7();
  @include fs-normal();
}

.description {
	@include text-8();
  @include fs-normal();
	text-transform: capitalize;

	span:first-child {
		margin-right: 10px;
	}
}

.likeButton {
	margin-top: 0 !important;
}
