@import "../../styles/mixins";

.supportPageWrapper {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	width: 100vw;
	min-height: 100vh;
	background-position: right;
	background-repeat: no-repeat;
	background-size: contain;
	background-color: var(--main-bg);
	overflow-y: auto;
	@include media-query('mobile') {
		background-size: cover;
		justify-content: flex-start;
	}
}
.headerSupport {
	position: static;
	width: 100%;
	background-color: transparent;
}

.supportContent {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	overflow-y: scroll;
	width: 535px;
	padding: 10px 0;

	@include media-query('mobile') {
		padding-bottom: 100px;
		margin-top: 40px;
		width: 345px;
	}
}

.supportTitle {
	font-size: 20px;
	margin-bottom: 70px;
	color: var(--white);

	@include media-query('mobile') {
		margin-bottom: 40px;
		font-size: 20px;
	}
}

.supportSubTitle {
	margin-bottom: 30px;
	font-size: 16px;
	color: var(--white);

	@include media-query('mobile') {
		margin-bottom: 20px;
	}
}

.supportDescription {
	font-size: 14px;
	color: var(--text-gray-1);
	margin-bottom: 70px;
	text-align: center;
	line-height: 150%;
	@include media-query('mobile') {
		margin-bottom: 40px;
	}
}

.textWrapper {
	width: 100%;
	margin-bottom: 40px;
}

.subDescription {
	font-size: 10px;
	color: var(--text-gray-1);
}

.avatarBlock {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 40px;

	@include media-query("mobile") {
		margin-bottom: 30px;
	}
}

.avatarMain {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #98989e;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	font-size: 40px;
	font-weight: bold;
	background-position: center;
	background-size: cover;
	color: var(--white);

	&:dir(ltr) {
		margin-right: 30px;
	}
	&:dir(rtl) {
		margin-left: 30px;
	}
	span {
		text-transform: capitalize;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 22;
	}
}
.avatarBlockName {
	font-size: 20px;
	color: var(--white);
	font-weight: normal;
	margin-bottom: 10px;
}

.avatarBlockId {
	font-size: 12px;
	color: var(--text-gray-1);
}

.addReviewWrapper {
	position: relative;
	padding: 200px 50px 10px 50px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	@include media-query('mobile') {
		padding: 40px 20px 10px 20px;

		.reviewModalContentDecor {
			position: absolute;
			top: 8px;
			left: 50%;
			transform: translateX(-50%);
			width: 130px;
			height: 5px;
			border-radius: 100px;
			background-color: var(--hover-gray-nav);
		}
	}


	.avatarMain {
		background-image: url("../../components/icons/details/av.jpg");
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
	}
	.addReviewModalTitle {
		font-size: 30px;
		margin-bottom: 30px;
		color: var(--white);

		@include media-query('mobile') {
			font-size: 20px;
			margin-bottom: 20px;
		}
	}

	.addReviewModalTitleSub {
		font-size: 14px;
		margin-bottom: 70px;
		color: var(--text-gray-1);
		@include media-query('mobile') {
			margin-bottom: 40px;
		}
	}
	.addReviewModalInfo {
		margin-top: 40px;
		font-size: 10px;
		color: var(--text-gray-1);

		@include media-query('mobile') {
			margin-top: 20px;
		}
	}
}

.serviceModalContent {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 30px;

	.serviceModalIcon {
		margin-bottom: 20px;
	}

	.addReviewModalTitleSub {
		text-align: center;
		margin-bottom: 0;
	}

	.addReviewModalTitle {
		text-align: center;
		margin-bottom: 0;
	}

	.authBtn {
		padding: 0 55px;
		height: 40px;
	}
}

