.inputWrapper {
	display: flex;
	align-items: center;
	max-width: 165px;
	width: 100%;
	justify-content: space-between;
}

.codeInput {
	border: none;
	border-bottom: 3px solid var(--secondary-border);
	border-radius: 0;
	font-size: 30px;
	font-weight: bold;
	color: var(--white);
	padding: 15px 5px;
	width: 100%;
	max-width: 30px;
}
