@import "../../../../styles/mixins";

.wrapper {
  @include flex-column(30px);
  box-sizing: content-box;

  @include media-query("mobile") {
    @include flex-column(30px);
    box-sizing: border-box;
  }
}

.title {
  @include text-2();
  @include fs-normal(var(--white));
}

.cardWrapper {
  @include flex-row(15px);
  max-width: 100%;
	width: 100%;
  overflow-x: scroll;
	padding-right: 10px;
	padding-bottom: 3px;
}
.cardWrapper::-webkit-scrollbar {
	transition: all 0.5s ease-in-out;
	height: 6px;
	margin-left: 20px;
}

.cardWrapper::-webkit-scrollbar-track {
	height: 0;
	width: 0;
}

.cardWrapper::-webkit-scrollbar-thumb {
	background-color: var(--scroll);
	border-radius: var(--br-30);
}
