@import "../../../../styles/mixins";

.cardWrapper {
	min-width: 294px;
	@include flex-row();
	align-items: center;
	height: 186px;
	border-radius: var(--br-8);
	padding: 10px 10px 7px 10px;
	border: 1px solid var(--secondary-border);
	position: relative;
	@include hover();
  @include hover();
	&:hover {
		border: 1px solid var(--main-button);
		cursor: pointer;
	}

	@include media-query('mobile') {
		&:hover {
			cursor: default;
			border: 1px solid var(--secondary-border);
		}
	}
}

.imgSimilarWrapper {
	position: relative;
	&:before {
		@include hover();
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: rgba(17, 17, 17, 0);
		border-radius: 10px;
	}
	@include hover();
	&:hover {
		cursor: pointer;
		&:before {
			background-color: rgba(17, 17, 17, 0.3);
		}
		.hoverPlayIcon {
			opacity: 1;
			@include hover();
			.wrapperPath {
				@include hover();
				fill-opacity: 0.3;
			}
		}
	}
	@include media-query('mobile') {
		&:hover {
			cursor: default;
			&:before {
				background-color: rgba(17, 17, 17, 0);
			}
			.hoverPlayIcon {
				opacity: 1;
				@include hover();
				.wrapperPath {
					opacity: 0;
				}
			}
		}
	}
}
.hoverPlayIcon {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	opacity: 0;
	transition: opacity 0.3s ease-in-out;
}
.image {
	border-radius: var(--br-8);
  object-fit: cover;
}

.textWrapper {
  @include flex-column(0);

  @include media-query("mobile") {
    @include flex-column(0);
  }
}

.movieTitle {
  @include text-6();
  @include fs-normal(var(--white));
	@include hover();
	line-height: 14px;
	margin-bottom: 19px;
}

.infoWrapper {
	@include flex-column(8px);
}

.rowInfoWrapper {
  @include flex-row(10px);
  @include text-8();
  @include fs-normal();
	align-items: center;
}

.badge {
	@include badge-md();
	@include fs-normal(var(--white));
	@include text-8();
	border-radius: 3px;
}

.country {
	@include text-8();
	@include fs-bold();
}

.ratingWrapper {
	width: fit-content;
	@include flex-row(5px);
	align-items: center;
	justify-content: center;
	margin-bottom: 19px;
	padding: 4px 7px;
	border-radius: 5px;
	border: 1px solid var(--outlined-border);
}

.rating {
	padding-top: 1px;
	@include text-7();
	@include fs-bold();
}

.ratingName {
  @include fs-normal();
}

.description {
  @include text-8();
  @include fs-normal();
}
