@import "../../styles/mixins";

.footerWrapperMobile {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1.8rem 4.3rem;
	background-color: var(--main-dark);
	z-index: 99999999999;
	color: var(--secondary-gray);
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	text-transform: uppercase;
}

.footerWrapperMobile {
	padding: 15px 20px 10px 20px;
	justify-content: space-between;
}

.footerWrapper {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 50px;

	background-color: var(--main-dark);
	z-index: 99999999999;
	color: var(--secondary-gray);
	border-top: 1px solid var(--main-bg);

	&:dir(rtl) {
		padding: 0 0 0 50px;
	}

	&:dir(ltr) {
		padding: 0 50px 0 0;
	}

	.links {
		display: flex;
		align-items: center;
		@include text-8();
		margin-left: 30px;

		a,
		span {
			@include hover();
			display: inline-block;
			padding: 5px;
			margin: 0 10px;

			&:hover {
				cursor: pointer;
				color: var(--white);
			}
		}
	}

	.copy {
		@include hover();
		@include text-8();
		@include flex-center();

		&:dir(rtl) {
			margin-right: auto;
		}

		&:dir(ltr) {
			margin-left: auto;
		}
		color: var(--secondary-gray);
		span {
			font-weight: bold;
			padding: 0 4px;
		}

		&:hover {
			cursor: pointer;
		}
	}
}

.footerModalWrapper {
	position: static;
	width: 100%;
}

.footerLogoWrapper {
	height: 50px;
	padding: 0 30px 0 50px;
	display: flex;
	align-items: center;
	justify-content: center;
	@include hover();
	&:hover {
		cursor: pointer;
		.footerLogoIcon {
			fill: var(--white)
		}
	}
}

.footerLogoIcon {
	@include hover();
}

.supportBtn {
	border-radius: 5px;
	border: 1px solid var(--secondary-border);
	padding: 8px 10px;
	font-size: 10px;


	display: flex;
	align-items: center;

	&:dir(rtl) {
		margin-left: 50px;

		svg {
			@include hover();
			margin-left: 10px;
		}
	}

	&:dir(ltr) {
		margin-right: 50px;

		svg {
			@include hover();
			margin-right: 10px;
		}
	}



	@include hover();

	&:hover {
		cursor: pointer;
		background-color: var(--secondary-border);
		color: var(--white);

		svg {
			path {
				@include hover();
				fill: var(--white);
			}
		}
	}
}
