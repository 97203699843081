@import "../../styles/mixins";

.headerWrapper {
	justify-content: space-between;
}

.topControlsWrapper {
	@include flex-column(20px);
}

.searchFieldWrapper {
	padding: 12px 20px;
	border: 1px solid var(--secondary-border);
	border-radius: 8px;
	@include flex-row(10px);
	align-items: center;

	@include media-query("mobile") {
		@include flex-row(10px);
	}

	svg {
		display: flex;
		flex-shrink: 0;
	}
}
.focused {
	border: 1px solid var(--hover-gray-nav);
}

.input {
	&:placeholder-shown ~ .label {
		@include text-6();
		cursor: text;
		top: 20px;
	}
	&::placeholder {
		color: var(--text-gray-1) !important;
	}

	&:focus {
		&::placeholder {
			color: var(--text-gray) !important;
		}
	}
}

.label {
	@include text-8();
}


.container {
	@include flex-row(100px);
	justify-content: space-between;
	padding: 150px 100px 80px 206px;
	background: var(--main-bg);

	@include media-query("mobile") {
		padding: 85px 15px;
		min-height: 100vh;
	}
}

.leftColumnWrapper {
	@include flex-column(70px);
	justify-content: space-between;
	width: 100%;

	@include media-query("mobile") {
		@include flex-column(30px);
	}
}

.leftColumnTopContent {
	@include flex-column(40px);

	@include media-query("mobile") {
		@include flex-column(30px);
	}
}

.contentWrapper {
	@include width-full(100%);
	@include flex-column(0);
	height: 100%;
	padding: 0 15px;
	max-width: 330px;
	box-sizing: border-box;
}

.chipsButton {
	@include flex-center();
	@include text-8();
	@include fs-normal(var(text-gray-1));
	@include hover();
	font-weight: bold;
	border-radius: var(--br-5);
	border: 1px solid #333333;
	width: 116px;
	height: 30px;
	gap: 5px;

	&:hover {
		border: 1px solid var(--hover-gray-nav);
		color: var(--white);
	}

	@include media-query("mobile") {
		flex-shrink: 0;
		width: auto;
		height: auto;
		padding: 8px 16px;
	}
}

.chipsButtonActive {
	border: 1px solid var(--secondary-border);
	background-color: var(--secondary-border);
	color: var(--white);
	&:hover {
		border: 1px solid var(--secondary-border);
	}
}

.chipsListWrapper {
	@include flex-row(10px);
	align-items: center;
	justify-content: flex-start;

	@include media-query("mobile") {
		overflow-x: scroll;
	}
}

.form__group {
	padding-top: 0;
}

.advertisement {
	width: 100%;
	height: 350px;
	border-radius: var(--br-20);
	margin-top: 50px;
}
