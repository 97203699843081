@import "../../../styles/mixins";

.customSelect {
	position: relative;
	width: 100%;
}

.selectBox {
	border: 1px solid var(--secondary-border);
	padding: 14px 20px;
	cursor: pointer;
	display: flex;
	text-transform: capitalize;
	justify-content: space-between;
	align-items: center;
	border-radius: 8px;
	font-size: 14px;
	color: var(--text-gray-1);
	height: 48px;
	@include hover();
}

.focused {
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.optionsContainer {
	padding: 10px 0;
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	background-color: var(--main-bg);
	z-index: 1000;
	border-bottom-right-radius: 8px;
	border-bottom-left-radius: 8px;
	border-left: 1px solid var(--secondary-border);
	border-right: 1px solid var(--secondary-border);
	border-bottom: 1px solid var(--secondary-border);
	overflow-y: scroll;
	overflow-x: hidden;
}

.option {
	padding: 10px 20px;
	cursor: pointer;
	font-size: 14px;
	text-transform: capitalize;
	color: var(--text-gray-1);
	@include hover();
}

.cardsSeparator {
	padding: 10px 20px;
	font-size: 12px;
	color: var(--text-gray-1);
}

.newCardOption {
	padding: 10px 20px;
	color: var(--white);
	display: flex;
	align-items: center;
	justify-content: flex-start;
  font-weight: bold;
	font-size: 14px;
	svg {
		margin-right: 10px;
	}

	&:hover {
		cursor: pointer;
	}
}

.selectItemCard {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	svg {
		margin-right: 10px;
	}
}

.option:hover {
	color: var(--white);
}

.arrow {
	display: flex;
	margin-left: 10px;
	position: absolute;
	&:dir(ltr) {
		right: 20px;
	}
	&:dir(rtl) {
		left: 20px;
	}
	top: 50%;
	transform: translateY(-50%);
	@include hover();
}

.arrowSwip {
	transform: rotate(180deg) translateY(50%);
}

.activeArrow {
	@include hover();
	stroke: var(--white);
}


.customScrollbar {
	overflow-y: scroll;
	&::-webkit-scrollbar {
		-webkit-appearance: none;
		display: block !important;
		width: 8px;
	}

	&::-webkit-scrollbar-track {
		border-bottom-right-radius: 8px !important;
		background: var(--secondary-border) !important;
	}

	&::-webkit-scrollbar-thumb {
		background-color: var(--main-button) !important;
		border-radius: 0 !important;
		box-sizing: border-box !important;
		border: none !important;
	}

	&::-webkit-scrollbar-thumb:hover {
		background: var(--main-button) !important;
	}

	///* Для Microsoft Edge и Internet Explorer */
	-ms-overflow-style: -ms-autohiding-scrollbar;
	&::-ms-scrollbar {
		width: 8px;
	}

	&::-ms-scrollbar-track {
		border-bottom-right-radius: 8px !important;
		background: var(--secondary-border) !important;
	}

	&::-ms-scrollbar-thumb {
		background-color: var(--main-button) !important;
		border-radius: 0 !important;
		box-sizing: border-box !important;
		border: none !important;
	}

	&::-ms-scrollbar-thumb:hover {
		background: var(--main-button) !important;
	}
}
