@import "../../../styles/mixins";

.wrapper {
	min-height: 100%;
	background: var(--main-bg);
}

.backButton {
	@include media-query("mobile") {
		display: none;
	}
}

.headerWrapper {
	justify-content: space-between;
}

.contentWrapper {
	@include flex-column(80px);
	align-items: center;
	@include width-full(900px);

	@include media-query("mobile") {
		gap: 40px;
	}
}

.timeDataWrapper {
	@include flex-column(40px);
	@include width-full(900px);
}

.incomeWrapper {
	@include flex-column(80px);
	@include width-full(900px);
	align-items: center;

	@include media-query("mobile") {
		gap: 40px
	}
}

.withBorder {
	padding-bottom: 80px;
	border-bottom: 1px solid var(--secondary-border);

	@include media-query("mobile") {
		padding-bottom: 40px;
	}
}

.sectionTitle {
	font-size: 40px;
	line-height: 48px;
	@include fs-normal(var(--white));

	@include media-query("mobile") {
		@include text-2();
	}
}

.cardsList {
	@include flex-row();
	justify-content: space-between;
	width: 100%;
}

.cardWrapper {
	@include flex-column();
	text-align: center;
	width: 100%;
	max-width: 100%;

	@include media-query("mobile") {
		gap: 10px;
	}
}

.title {
	@include text-3();
	@include fs-normal();

	@include media-query("mobile") {
		@include text-7();
	}
}

.income {
	@include text-3();
	@include fs-bold();

	@include media-query("mobile") {
		@include text-5();
	}
}

.totalIncome {
	@include text-3();
	@include fs-bold();

	@include media-query("mobile") {
		@include text-5();
		color: var(--text-gray-1)
	}
}

.totalIncomeCounterWrapper {
	@include flex-column(30px);
	align-items: center;

	@include media-query("mobile") {
		gap: 10px;
	}
}

.totalIncomeCounter {
	@include text-2();
	@include fs-normal();

	@include media-query("mobile") {
		@include fs-bold();
	}
}

.totalIncomeTotalCounter {
	@include text-1();
	@include fs-bold();

	@include media-query("mobile") {
		@include text-2();
		color: var(--text-gray-1);
	}
}

.reportButton {
	padding: 15px 77px;
}
