@import "../../../styles/mixins";

.modalBackdrop {
	background: rgba(0, 0, 0, 0.6);
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 2211111111111111110000;
}

.sidebar {
	background: var(--main-gray-hover);
	position: fixed;
	right: 0;
	top: 0;
	height: 100%;
	width: 500px;
	z-index: 22211111111111111110000;
	overflow-y: auto;
	transition: transform 300ms ease-in-out;

	@include media-query("mobile") {
		width: 100%;
		height: 50%;
		bottom: 0;
		left: 0;
		border-radius: 20px 20px 0 0;
	}
}


.sidebar::-webkit-scrollbar {
	width: 0;
}
