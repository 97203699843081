.form__field {
	outline: none;
	background: transparent;
	padding: 15px 20px;
	border-radius: 8px;
	border: 1px solid var(--secondary-border);
	color: var(--white);
	width: 100%;

	&:dir(ltr) {
		text-align: left;
	}
	&:dir(rtl) {
		text-align: right;
	}

	&:-webkit-autofill {
		-webkit-transition-delay: 9999s;
		transition-delay: 9999s;
	}

	&:-webkit-autofill:focus {
		-webkit-transition-delay: 9999s;
		transition-delay: 9999s;
	}

	&:-webkit-autofill:hover {
		-webkit-transition-delay: 9999s;
		transition-delay: 9999s;
	}

	&::placeholder {
		color: var(--text-gray-1);
	}

	&:focus {
		border-color: var(--hover-gray-nav);
		color: var(--white);
		&::placeholder {
			color: var(--text-gray);
		}
	}
}
