@import "../../styles/mixins";

.payPageWrapper {
	min-height: 100vh;
	background-color: var(--main-bg);
	padding-top: 150px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-direction: column;

	@include media-query('mobile') {
		padding-top: 95px;
		padding-left: 15px;
		padding-right: 15px;
	}
}

.payForm {
	max-width: 600px;
	margin-bottom: 100px;
}

.payTitle {
	font-size: 30px;
	margin-bottom: 30px;
	color: var(--white);

	@include media-query('mobile') {
		text-align: center;
	}
}

.payDescription {
	font-size: 14px;
	font-weight: bold;
	margin-bottom: 30px;
	color: var(--white);
}

.payMethod {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 15px 20px;
	border-radius: 8px;
	border: 1px solid var(--secondary-border);
	margin-bottom: 30px;
	color: var(--text-gray-1);
	font-size: 14px;

	svg {
		margin-right: 10px;
	}
}


.cardBlockTitle {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	div:first-child {
		font-size: 14px;
		font-weight: bold;
		color: var(--white);
	}

	.cardBlockLogo {
		display: flex;
		align-items: center;

		svg:first-child {
			margin-right: 10px;
		}
	}
}

.submitButtonWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;

	@include media-query('mobile') {
		flex-direction: column-reverse;
		align-items: flex-start;
	}
}

.submitButtonDescription {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
	color: var(--text-gray-1);
	svg {
		margin-right: 20px;
	}
}

.submitButton {
	height: 48px;
	padding: 0 47px;

	@include media-query('mobile') {
		width: 100%;
		margin-bottom: 30px;
	}
}


.payPolicy {
	font-size: 10px;
	color: var(--text-gray-1);
	line-height: 17px;
}

.payInfo {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	font-size: 10px;
	color: var(--text-gray-1);
	margin-bottom: 30px;

	span {
		font-weight: bold;
	}
	svg {
		margin-right: 10px;
	}
}

.customSelectWrapper {
	margin-bottom: 30px;
	width: 100%;
}
