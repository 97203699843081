@import "../../styles/mixins";

.greetingsPageWrapper {
	width: 100%;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
	flex-direction: column;
	background-image: url("../../static/imgs/bg.jpg");
	background-repeat: no-repeat;
	background-position: right;
	background-size: contain;

	@include media-query('mobile') {
		background-size: cover;
		justify-content: center;
		background-image: url("../../static/imgs/mobileGreetingsBg.jpg");
	}
}

.header {
	width: 100%;
	height: 50px;
	background-color: transparent;
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-query('mobile') {
		position: fixed;
		top: 0;
	}
}

.contentWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	max-width: 275px;
	width: 100%;
}

.title {
	font-size: 30px;
	margin-bottom: 15px;
	color: var(--white);
}

.description {
	font-size: 14px;
	text-align: center;
	margin-bottom: 30px;
	color: var(--white);

	span {
		font-weight: bold;
	}
}

.btn {
	height: 48px;
	width: 100%;
	background-color: var(--white);
	color: var(--main-dark);

	&:hover {
		color: var(--white);
		background-color: var(--text-gray);
	}
}
