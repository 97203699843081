@import "../../../styles/mixins";

.profilePageWrapper {
	min-height: 100vh;
	background-color: var(--main-bg);
	padding-top: 150px;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-bottom: 80px;

	@include media-query("mobile") {
		padding: 95px 0;
	}
}

.profilePageContentWrapper {
	padding: 0 150px;
	max-width: 926px;
	width: 100%;
	display: flex;
	align-items: flex-start;
	justify-content: center;
	gap: 100px;

	@include media-query("mobile") {
		padding: 0 15px;
	}

	.pageLeftContent {
		min-width: 315px;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		@include media-query("mobile") {
			align-items: center;
		}

		.avatarBlock {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 30px;

			@include media-query("mobile") {
				flex-direction: column;
			}
		}

		.uploadPhotoBtn {
			margin-bottom: 50px;
			font-weight: bold;
			font-size: 14px;
			color: var(--border-gray);
			line-height: 25px;
			@include hover();

			&:hover {
				color: var(--white);
				cursor: pointer;
			}
		}

		.navigationBlock {
			width: 100%;
			div {
				font-weight: bold;
				font-size: 14px;
				color: var(--border-gray);
				margin-bottom: 28px;
				line-height: 25px;
				@include hover();

				&:hover {
					color: var(--white);
					cursor: pointer;
				}
			}

			border-bottom: 1px solid var(--secondary-border);

			@include media-query("mobile") {
				padding-bottom: 20px;
			}

			.active {
				color: var(--white);
			}

			.mobileNavItem {
				padding: 15px 20px;
				margin-bottom: 10px;
				border-radius: 8px;
				color: var(--text-gray-1);
				background-color: var(--main-gray-hover);

				display: flex;
				align-items: center;
				justify-content: space-between;
			}
		}

		.prePayWrapper {
			width: 100%;
			border-bottom: 1px solid var(--secondary-border);
			padding-top: 30px;
			padding-bottom: 30px;
		}
		.prePayBlock {
			display: flex;
			align-items: center;
			justify-content: flex-start;
			margin-bottom: 30px;
			svg {
				margin-right: 10px;
			}

			.prePayTitle {
				font-size: 14px;
				color: var(--text-gray-1);
				margin-bottom: 2px;
			}
			.prePayButton {
				font-size: 14px;
				font-weight: bold;
				color: var(--white);

				@include hover();

				&:hover {
					color: var(--white);
					cursor: pointer;
				}
			}

			@include media-query('mobile') {
				padding-bottom: 30px;
				border-bottom: 1px solid var(--secondary-border);
				justify-content: center;
			}
		}


		.saleBlock {
			display: flex;
			align-items: center;
			justify-content: space-between;
			width: 100%;

			@include media-query('mobile') {
				justify-content: center;
			}
			.saleBlockInvite {
				font-size: 14px;
				font-weight: bold;
				color: var(--text-gray-1);
				display: flex;
				align-items: center;

				@include media-query('mobile') {
					margin-right: 31px;
				}

				@include hover();

				&:hover {
					color: var(--white);
					cursor: pointer;
				}
				svg {
					&:dir(ltr) {
						margin-right: 10px;
					}
					&:dir(rtl) {
						margin-left: 10px;
					}

				}
			}

			.saleBlockWrapper {
				display: flex;
				flex-direction: column;
				align-items: flex-end;

				@include media-query('mobile') {
					margin-left: 31px;
				}
			}
			.saleBlockSale {
				font-size: 10px;
				font-weight: bold;
				color: var(--white);
				display: flex;
				align-items: center;
				svg {
					&:dir(ltr) {
						margin-right: 10px;
					}
					&:dir(rtl) {
						margin-left: 10px;
					}
				}
			}

			.saleBlockSaleDescr {
				font-size: 10px;
				color: var(--text-gray-1);
			}
		}
	}

	.logOut {
		font-size: 14px;
		font-weight: bold;
		color: var(--text-gray-1);
		margin-top: 30px;
		line-height: 25px;
		@include hover();

		&:hover {
			cursor: pointer;
			color: var(--white);
		}

		@include media-query("mobile") {
			text-align: center;
			width: 100%;
			padding: 30px 0;
			margin-top: 10px;
			border-top: 1px solid var(--secondary-border);

		}
	}
	.pageRightContent {
		min-width: 515px;
		width: 100%;
	}
}

.accountTitle {
	font-size: 20px;
	color: vaR(--white);
	margin-bottom: 30px;
}

.avatarMain {
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background-color: #98989e;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	&:dir(ltr) {
		margin-right: 30px;
	}
	&:dir(rtl) {
		margin-left: 30px;
	}
	font-size: 40px;
	font-weight: bold;
	color: var(--white);
	background-position: center;
	background-size: cover;
	.uploadPhotoHover {
		position: absolute;
		right: 0;
		top: 0;
		left: 0;
		bottom: 0;
		opacity: 0;
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 23;
		background-color: rgba(17, 17, 17, 0.8);
		cursor: pointer;
		transition: all ease-in-out .3s;
	}

	&:hover {
		.uploadPhotoHover {
			opacity: 1;
		}
	}
	span {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 22;
	}

	@include media-query("mobile") {
		margin-right: 0;
		margin-bottom: 30px;
	}
}

.avatarBlockName {
	font-size: 30px;
	color: var(--white);
	font-weight: normal;
	margin-bottom: 10px;
}

.avatarBlockId {
	font-size: 14px;
	color: var(--text-gray-1);
}

.dateTitle {
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: bold;
	color: var(--white);
}
.selectWrapper {
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 30px;
}

.submitBlock {
	margin-top: 30px;
}

.submitBlockQuestion {
	margin-bottom: 20px;
	font-size: 14px;
	color: var(--white);
	font-weight: bold;
}
.toggle {
	display: flex;
	align-items: center;

	&:dir(ltr) {
		label {
			margin-right: 20px;
		}
	}
	&:dir(rtl) {
		label {
			margin-left: 20px;
			margin-right: 0;
		}
	}


	.descr {
		font-size: 14px;
		font-weight: bold;
		color: var(--text-gray-1);
	}
}

.buttonWrapper {
	display: flex;
	align-items: center;
	margin-top: 30px;

	@include media-query('mobile') {
		flex-direction: column;
		width: 100%;
	}

	.button {
		width: 209px;
		height: 48px;
		display: flex;
		align-items: center;
		justify-content: center;


		&:dir(ltr) {
			margin-right: 30px;
		}
		&:dir(rtl) {
			margin-left: 30px;
		}
		@include media-query('mobile') {
			width: 100%;
			margin-right: 0;
			margin-bottom: 10px;
		}
	}

	.checkmark {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 12px;
		color: var(--white);

		@include hover();

		&:dir(ltr) {
			svg {
				margin-right: 10px;
			}
		}
		&:dir(rtl) {
			svg {
				margin-left: 10px;
			}
		}
	}
}


.tabsBlock {
	width: 99%;
	height: 48px;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	border: 1px solid var(--secondary-border);
	margin-bottom: 30px;
	border-radius: 8px;

	.additionalClass {
		border-right: 1px solid var(--secondary-border);
	}

	.tab {
		display: flex;
		align-items: center;
		justify-content: center;
		flex: 1;
		font-size: 14px;
		font-weight: bold;
		color: var(--white);
    @include hover();

		@include media-query('mobile') {
			font-size: 12px;
		}

		&:hover {
			cursor: pointer;
			background-color: var(--secondary-border);
		}

		&:first-child {
			&:dir(ltr) {
				border-top-left-radius: 7px;
				border-bottom-left-radius: 7px;
			}
			&:dir(rtl) {
				border-top-right-radius: 7px;
				border-bottom-right-radius: 7px;
			}

		}

		&:last-child {
			&:dir(ltr) {
				border-top-right-radius: 7px;
				border-bottom-right-radius: 7px;

			}
			&:dir(rtl) {
				border-top-left-radius: 7px;
				border-bottom-left-radius: 7px;
			}

		}
	}

	.active {
		background-color: var(--main-button);
		&:hover {
			background-color: var(--main-button);
		}
	}
}

.descriptionMain {
	font-size: 14px;
	color: var(--white);
	margin-bottom: 20px;
}

.descriptionSecondary {
	font-weight: bold;
	color: var(--white);
	margin-bottom: 20px;
}

.cardItemWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	border: 1px solid var(--secondary-border);
	border-radius: 8px;
	padding: 20px;
	margin-bottom: 20px;
	.cardContentPart {
		display: flex;
		align-items: center;
		justify-content: center;

		.cardNumber {
			font-size: 20px;
			color: var(--white);
			margin-left: 20px;

			@include media-query('mobile') {
				margin-left: 12px;
			}
		}

		.cardDate {
			font-size: 14px;
			color: var(--text-gray-1);
			margin-right: 30px;

			@include media-query('mobile') {
				margin-right: 12px;
			}
		}
	}

	@include hover();
	&:hover {
		background-color: var(--secondary-border);
		cursor: pointer;

		.closeIcon {
			stroke: #ffffff;
		}
	}
}
.closeIcon {
	@include hover();
}
.submit {
	color: var(--main-dark);
	background-color: var(--white);
	width: 186px;
	height: 48px;
	margin-top: 10px;

	@include media-query('mobile') {
		width: 100%;
	}

	&:hover {
		background-color: var(--text-gray);
		color: var(--white);
	}
}

.formWrapper {
	margin-top: 40px;
}

.formHeaderWrapper {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	.formTitle {
		font-size: 30px;
		color: var(--white);
	}
	.cardsLogoWrapper {
		display: flex;
		align-items: center;
		svg	{
			display: inline-block;
			margin-left: 10px;
		}
	}
}


.newCardForm {
	margin-bottom: 30px;

	.secondaryInputsWrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-top: 20px;
		input:first-child {
			margin-right: 20px;
		}
	}
}

.additionalClass {
	margin-top: 0;
}

.agreenments {
	font-size: 10px;
	color: var(--text-gray-1);
	margin-top: 30px;
	line-height: 15px;

	a {
		color: var(--main-button);
	}
}

.subscriptionSubTitle {
	font-size: 14px;
	color: var(--white);
	margin-bottom: 20px;
}

.subscriptionDescr {
	font-size: 14px;
	color: var(--white);
	margin-bottom: 20px;
	font-weight: bold;
}

.subscriptionCardWrapper {
	padding: 20px;
	border-radius: 8px;
	border: 1px solid var(--secondary-border);
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 20px;

	@include hover();

	&:hover {
		cursor: pointer;
		background-color: var(--main-gray-hover);
	}
	@include media-query('mobile') {
		&:hover {
			cursor: default;
			background-color: inherit;
		}
	}

	.cartAmountWrapper {
		display: flex;
		align-items: flex-start;
		justify-content: flex-start;

		@include media-query('mobile') {
			flex-direction: column-reverse;
			align-items: flex-start;
		}
	}

	.amount {
		font-size: 20px;
		color: var(--white);
		margin-bottom: 15px;
	}

	.total {
		font-size: 12px;
		color: var(--text-gray-1);
	}

	.discount {
		margin-top: 3px;
		padding: 4px 10px;
		background-color: var(--main-button);
		color: var(--white);
		font-size: 12px;
		font-weight: bold;
		border-radius: 50px;

		&:dir(ltr) {
			margin-left: 10px;
		}
		&:dir(rtl) {
			margin-right: 10px;
		}

		@include media-query('mobile') {
			margin-left: 0;
			margin-bottom: 10px;
		}
	}

	.cardRightContent {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.priceWrapper {
			text-align: center;
		}

		@include media-query('mobile') {
			width: fit-content;
			flex-direction: column-reverse;
			align-items: center;

			.priceWrapper{
				display: flex;
				flex-direction: row-reverse;
				align-items: center;
			}
		}
		.price {
			font-size: 20px;
			color: var(--white);
			span {
				font-size: 16px;
				font-weight: normal;
			}
		}

		.regularPrice {
			font-size: 12px;
			margin-top: 10px;
			text-decoration: line-through;
			@include media-query('mobile') {
				margin-top: 0px;
				margin-right: 10px;
			}
		}
	}
	.connectButton {
		&:dir(ltr) {
			margin-left: 30px;
		}
		&:dir(rtl) {
			margin-right: 30px;
		}
		width: 146px;
		height: 48px;
		background-color: var(--white);
		color: var(--main-dark);
		@include hover();

		@include media-query('mobile') {
			margin-left: 0;
			margin-bottom: 10px;
		}

		&:hover {
			background-color: var(--main-button);
			color: var(--white);
		}
	}
}

.newSubButton {
	height: 48px;
	padding: 0 30px;

	@include media-query('mobile') {
		width: 100%;
	}
}

.mySubsTitle {
	font-size: 14px;
	color: var(--white);
	margin-bottom: 30px;
}

.mySubsListTitle {
	font-size: 14px;
	color: var(--white);
	font-weight: bold;
	margin-bottom: 20px;
}

.subsListWrapper {
	padding-bottom: 20px;
	border-bottom: 1px solid var(--secondary-border);
	margin-bottom: 20px;
}

.mySubsBottomMenu {
	display: flex;
	align-items: center;
	justify-content: flex-start;

	p	{
		margin-right: 30px;
		font-size: 14px;
		color: var(--text-gray-1);
		font-weight: bold;

		@include hover();

		&:hover {
			cursor: pointer;
			color: var(--white);
		}
	}
}

.mySubCardWrapper {
	padding: 27px 20px;
	background-color: var(--main-gray-hover);
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media-query('mobile') {
		padding: 20px;
	}
}

.mySubCardWrapperContent {
	@include media-query('mobile') {
		width: 50%;
	}
}

.mySubCardAmount {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	margin-bottom: 15px;

	@include media-query('mobile') {
		flex-direction: column-reverse;
		align-items: flex-start;
	}

	.mySubCardAmountTitle {
		font-size: 24px;
		color: var(--white);
		&:dir(ltr) {
			margin-right: 10px;
		}
		&:dir(rtl) {
			margin-left: 10px;
		}
	}

	.mySubDiscount {
		padding: 4px 10px;
		background-color: var(--main-button);
		color: var(--white);
		font-size: 12px;
		font-weight: bold;
		border-radius: 50px;

		@include media-query('mobile') {
			margin-bottom: 10px;
		}
	}
}
.mySubCardExpiration {
	font-size: 12px;
	color: var(--text-gray-1);

	p:first-child {
		margin-bottom: 5px;
	}
}

.mySubCardButton {
	height: 48px;
	padding: 0 25px;
	background-color: var(--white);
	color: var(--main-dark);
	margin-bottom: 10px;

	&:hover {
		background-color: var(--text-gray);
		color: var(--white);
	}
}

.mySubCardInfo {
	display: flex;
	align-items: center;
	flex-direction: column;

	font-size: 12px;
	color: var(--text-gray-1);

	//p:last-child {
	//	text-decoration: line-through;
	//	margin-top: 5px;
	//}
}
.removeCardModalWrapper {
	max-width: 390px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.removeCardModalTextContent {
		margin-bottom: 30px;
		padding-right: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		color: var(--white);
		div {
			text-align: center;
		}
	}

	.removeCardModalDescr {
		font-size: 16px;
		line-height: 24px;
		color: var(--text-gray-1);
		margin-bottom: 30px;
		text-align: center;
	}

	.removeCardModalSubmit {
		height: 48px;
		padding: 0 30px;
	}

	.removeCardModalCancel {
		height: 48px;
		padding: 0 30px;
		background-color: transparent;
		color: var(--white);
		border: 1px solid var(--secondary-border);
		margin-left: 30px;
	}

	.removeConfirm {
		height: 48px;
		padding: 0 30px;
		background-color: transparent;
		color: var(--white);
		border: 1px solid var(--secondary-border);
	}
}

.addCardModalWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.addCardModalTextContent {
		margin-bottom: 40px;
		padding-right: 44px;
		display: flex;
		align-items: flex-end;
		justify-content: flex-end;
		flex-direction: column;
	}
	.addCardModalTitle {
		font-size: 30px;
		color: var(--white);
		margin-bottom: 30px;
	}

	.addCardModalDescr {
		font-size: 16px;
		color: var(--text-gray-1);
	}
	.addCardModalSubmit {
		height: 48px;
		padding: 0 30px;
	}
}

.subscriptionModalWrapper {
	max-width: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	.removeSubModalTitle {
		margin-bottom: 30px;
		padding-right: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 30px;
		color: var(--white);
		text-align: center;
	}

	.removeSubModalDescr {
		font-size: 16px;
		line-height: 24px;
		color: var(--text-gray-1);
		margin-bottom: 30px;
		text-align: center;
	}

	.subscriptionModalButtonWrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;

		@include media-query('mobile') {
			flex-direction: column;
		}
	}

	.confirm {
		height: 48px;
		padding: 0 30px;

		@include media-query('mobile') {
			width: 100%;
			margin-bottom: 15px;
		}
	}

	.cancel {
		height: 48px;
		padding: 0 30px;
		background-color: transparent;
		color: var(--white);
		border: 1px solid var(--secondary-border);

		@include media-query('mobile') {
			width: 100%;
		}
	}
}

.links {
	font-size: 12px;
	padding: 20px 0;
	//border-bottom: 1px solid var(--secondary-border);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	width: 100%;

	a:last-child,
	span:last-child {
		margin-bottom: 0;
	}
	a,
	span {
		color: var(--text-gray-1);
		display: inline-block;
		padding: 10px;
		margin-bottom: 10px;
	}
}

.supportBtn {
	border-radius: 5px;
	border: 1px solid var(--secondary-border);
	padding: 8px 10px;
	font-size: 10px;

	display: flex;
	align-items: center;

	svg {
		@include hover();
		margin-right: 10px;
	}

	@include hover();

	&:hover {
		cursor: pointer;
		background-color: var(--secondary-border);
		color: var(--white);

		svg {
			path {
				@include hover();
				fill: var(--white);
			}
		}
	}
}

.langBtns {
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 30px 0;
	font-size: 14px;
	font-weight: bold;
	color: var(--text-gray-1);

	.activeBtn {
		color: var(--white);
	}
	div {
		padding: 10px;
	}
}

.app {
	span	{
		font-weight: bold;
	}
	font-size: 10px;
}
