@import "../../../../styles/mixins";

.mainLoaderWrapper {
	@include flex-center();
	height: 100vh;
	width: 100%;
}
.cover {
  position: absolute;
  top: 60px;
  bottom: 50px;
  left: 0;
  right: 0;
  z-index: 999999999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 60px;
}

.playWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.votes {
  color: var(--white);
  font-size: 70px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 40px;
}

.genres {
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  margin-bottom: 20px;
  text-transform: uppercase;

  span {
    margin-right: 20px;
  }
}

.year {
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  padding: 5px 18px;
  background-color: var(--main-purple);
  border-radius: 100px;
  max-width: fit-content;
}

.actorsWrapper {
  max-width: 330px;
  width: 100%;
  max-height: 360px;
  height: 100%;
  @include flex-center();
  flex-wrap: wrap;
}

.bgCoverDesctop {
  position: absolute;
  top: 7%;
  bottom: 8%;
  left: -2px;
  right: -2px;
  z-index: 99997;
  background-size: cover;
  background-repeat: no-repeat;
}

.bgCoverDesctop:before,
.bgCover:before {
  content: "";
  position: absolute;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.85) 50%,
    rgba(0, 0, 0, 1) 100%
  );
}

.footerContentDataCtr {
  color: var(--white);
  font-size: 16px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  text-transform: uppercase;
  margin-bottom: 40px;
}

.actorWrapper {
  @include flex-center();
  flex-direction: column;
  max-width: 50%;
  width: 100%;
}

.actorWrapperHover:hover {
  cursor: pointer;
}

.actorWrapperHover:hover .photo {
  border: 5px solid var(--main-purple);
}

.photo {
  max-width: 110px;
  width: 100%;
  height: 110px;
  background-size: cover;
  background-position: center;
  border-radius: 100px;
  border: 5px solid var(--border-gray);
  line-height: 18px;
  margin-bottom: 20px;
  transition: all 0.2s ease-in-out;
}

.actorName {
  color: var(--white);
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  text-transform: uppercase;
}

.headerWrapper {
  justify-content: space-between;
}

.desctopModal {
  background-color: var(--main-bg);
	position: relative;
}

.pageScrollContainer::-webkit-scrollbar {
	transition: all 0.5s ease-in-out;
	width: 0;
}

.pageScrollContainer::-webkit-scrollbar-track {
	height: 0;
	width: 0;
}

.pageScrollContainer::-webkit-scrollbar-thumb {
	background-color: var(--scroll);
	border-radius: var(--br-30);
}

.desctopModal::-webkit-scrollbar {
	width: 5px;
}

.desctopModal::-webkit-scrollbar-track {
	background: var(--overlay-white-26);
	border-radius: var(--br-30);
}

.desctopModal::-webkit-scrollbar-thumb {
	background-color: var(--scroll);
	border-radius: var(--br-30);
}

.backButton:hover {
  cursor: pointer;
}

.emptyHeader {
  width: 63px;
  height: 63px;
  @include flex-center();
}

.firstSection {
  width: 100%;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  display: flex;
  align-items: flex-end;

  @include media-query("mobile") {
    margin-bottom: 210px;
    min-height: auto;
  }
}

.posterWrapper,
.poster {
  border-radius: var(--br-8);
}

.posterWrapper {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  min-width: 146px;
  height: 220px;
	&:dir(ltr) {
		margin-right: 40px;
	}
	&:dir(rtl) {
		margin-left: 40px;
	}

  @include media-query("mobile") {
    margin-right: 30px;
  }
}

.firstSectionInfo {
  padding: 260px 100px 60px 100px;
  position: relative;
  z-index: 99;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  width: 100%;

  @include media-query("mobile") {
    padding: 105px 15px 15px;
    align-items: center;
  }
}

.firstSection:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #111 97%);
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  height: 100%;
  width: 100%;

  @include media-query("mobile") {
    @include flex-column(0);
		justify-content: space-between;
    min-height: auto;
  }
}

.title {
  font-size: 30px;
	margin-bottom: 30px;
  font-weight: normal;
  color: var(--white);

  @include media-query("mobile") {
    @include text-9();
		margin-bottom: 20px;
	}
}

.infoIconsWrapper {
  @include flex-center();
	gap: 40px;
	height: 50px;

  @include media-query("mobile") {
    position: absolute;
    bottom: -42px;
    left: 15px;
    width: calc(100% - 30px);
    gap: 35px;
    justify-content: center;
  }
}

.infoWrapper {
  font-size: 20px;
  font-weight: bold;
  color: var(--white);
}

.reit {
	width: fit-content;
	display: flex;
	align-items: center;
	justify-content: center;
	svg {
		margin-bottom: 1px;
	}
	span {
		@include fs-bold();
		@include text-4();
		display: flex;
		margin-left: 5px;
		line-height: 10px;
		padding-top: 2px;
	}

	border: 1px solid rgba(250, 250, 250, 0.3);

	padding: 9px 9px 9px 8px;
	border-radius: 8px;
	margin-bottom: 30px;
  @include media-query("mobile") {
    span {
			@include text-6();
			line-height: 8px;
			padding-top: 0px;
		}
		margin-bottom: 20px;
	}
}

.ganres {
  @include flex-row(30px);
  @include text-7();
  @include fs-bold();
	margin-bottom: 30px;

  @include media-query("mobile") {
		@include text-8();
    flex-wrap: wrap;
    gap: 15px;
    text-transform: uppercase;
		margin-bottom: 0;
		order: 3;
	}
}

.infoWrapperControlls {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  @include media-query("mobile") {
    @include flex-column();
  }
}

.controllsMainButton {
	width: 200px;
	font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--white);
  padding: 17px 32px;
  background-color: var(--main-purple);
  border-radius: 8px;
  border: none;
  @include flex-center();
  transition: all 0.2s ease-in-out;

	&:dir(ltr) {
		margin-right: 20px;
	}
	&:dir(rtl) {
		margin-left: 20px;
	}
  @include media-query("mobile") {
    position: absolute;
    bottom: -120px;
    left: 15px;
    max-width: 100%;
    width: calc(100% - 30px);
    @include text-7();
  }

  &:hover {
    cursor: pointer;
    background-color: var(--hover-red);
  }

	@include media-query("mobile") {
		&:hover {
			cursor: default;
			background-color: var(--main-purple);
		}
	}
}

.outlineSecondaryButton {
	@include text-6();
	@include fs-bold();
	padding: 17px 32px;
	border-radius: 8px;
	gap: 10px;
	transition: all 0.2s ease-in-out;
	border: 1px solid var(--outlined-border);
	background: transparent;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;

	&:dir(ltr) {
		margin-right: 50px;
	}
	&:dir(rtl) {
		margin-left: 50px;
	}
	span {
		line-height: 10px;
	}

	@include hover();
	&:hover {
		cursor: pointer;
		background: var(--white);

		span {
			color: var(--main-dark)
		}

		svg {
			path {
				stroke: var(--main-dark);
			}
		}
	}
}

.outlineButton {
  display: none;
  @include text-6();
  @include fs-bold();
  padding: 14px 32px;
  border-radius: 8px;
  gap: 15px;
  transition: all 0.2s ease-in-out;
  border: 1px solid var(--secondary-border);
  background: transparent;
  width: calc(100% - 30px);

  @include media-query("mobile") {
    @include flex-center();
    position: absolute;
    left: 15px;
    bottom: -191px;
  }
}

.addsButtonsWrapper {
  @include flex-center();

  @include media-query("mobile") {
    display: none;
  }
}

.addsButton {
  margin-left: 20px;
  background: transparent;
  padding: 18px 40px;
  border: 1px solid var(--secondary-border);
  border-radius: var(--br-5);

  &:hover {
    cursor: pointer;
  }
}

.contentWrapper {
  @include flex-column(60px);
	&:dir(ltr) {
		padding: 50px 0 160px 100px;
	}
	&:dir(rtl) {
		padding: 50px 100px 160px 0;
	}

  @include media-query("mobile") {
	  padding: 40px 15px 90px;
	  @include flex-column(40px);
  }

}

.topContent {
  @include flex-row();
  justify-content: space-between;
  align-items: flex-start;

  @include media-query("desktopXL") {
    gap: 80px;
    justify-content: flex-start;
  }

  @include media-query("mobile") {
    @include flex-column(50px);
  }
}

.bottomContent {
  @include flex-row();
  justify-content: space-between;

  @include media-query("desktopXL") {
    @include flex-column(70px);
    min-width: 450px;
    justify-content: flex-start;
    overflow-x: hidden;
  }

  @include media-query("mobile") {
    @include flex-column();
    padding: 0 15px 40px;
  }
}

.advertisement {
  width: 535px;
  height: 360px;
  border-radius: var(--br-20);
  margin-top: 70px;

  @include media-query("desktopXL") {
    @include width-full(450px);
    margin-top: 0;
  }

  @include media-query("mobile") {
    display: none;
  }
}

.leftColumn {
  @include width-full(605px);
  @include flex-column(60px);

  @include media-query("mobile") {
    @include width-full(767px);
    @include flex-column(40px);
  }
}

.rightColumn {
  @include flex-column(70px);

  @include media-query("desktopXL") {
    min-width: 535px;
  }

  @include media-query("mobile") {
    @include flex-column(40px);
    padding: 0 15px;
  }
}

.mainDescriptionWrapper {
  @include flex-column(30px);

  @include media-query("mobile") {
    @include flex-column(30px);
    padding: 0 15px;
  }
}

.sectionTitle {
  @include text-3();
  @include fs-normal(var(--white));
}

.overviewTitle {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
  color: var(--white);
}

.overviewDescription {
  @include text-6();
  @include fs-normal();

  .descText {
	  max-width: 958px;
  }
}

.infoBarItemWrapperExtra {
	min-width: auto;
}
