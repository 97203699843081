@import "../../../styles/mixins";

.icon {
  display: flex;
  align-items: center;

	&:dir(ltr) {
		margin-right: 10px;
	}
	&:dir(rtl) {
		margin-left: 10px;
	}
}

.iconMobile {
  margin-right: 0;
}

.itemInfoBar {
  display: flex;
  align-items: center;
  justify-content: flex-start;
	gap: 40px;
	height: 100%;
}

.itemInfoBarMobile {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.infoBarItemWrapperMobile,
.infoBarItemWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: var(--white);
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  position: relative;
  //min-width: 33%;
	height: 100%;

  @include media-query("mobile") {
    min-width: auto;
  }
}

.infoBarContentWrapper {
	display: flex;
	align-items: center;
	height: 100%;

	@include hover();
	&:hover {
		.savedIcon,
		.secondaryShareIcon,
		.shareIcon,
		.commentIcon,
		.mainLikeIcon {
			fill: var(--white);
		}

		cursor: pointer;
	}
}

.infoBarItemWrapperMobile {
  font-size: 10px;
  min-width: 33%;
}

.footerContent {
  font-size: 20px;
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 9999999;
	gap: 50px;
  transform: translateY(-5px) translate3d(0, 0, 200px);
}

.footerContentButton {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  color: var(--white);
  padding: 15px 59px;
  background-color: var(--main-purple);
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.footerContentButton:hover {
  cursor: pointer;
  color: var(--main-dark);
  background-color: var(--hover-red);
}

.footerContentButton span {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 10px;
  display: inline-block;
}

.footerContentSavedButton {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--white);
}

.footerContentSavedButton:hover {
  cursor: pointer;
}

.footerContentSavedButton span {
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-left: 15px;
  display: inline-block;
}

.videoBlockContent {
  position: relative;
  width: 100%;
  max-width: 60%;
}

.videoBlockContentMobile {
  max-width: 100%;
}

.unmute {
  position: absolute;
  top: 140px;
  left: 20px;
  z-index: 999999999;
}
.videoWrapper {
  width: 100%;
  position: relative;
}

.rightInfoBlock {
  position: absolute;
  bottom: 0;
  display: flex;
  height: 100%;
  right: -83px;
}

.bottomInfoBlock {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

.mobileProgressController {
  position: absolute;
  bottom: 4px;
  left: 0;
  right: 0;
  z-index: 999999999;
}

.loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--main-dark);
  z-index: 9998;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 350px 550px 450px 550px;
}

.scrollCover {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 88888;
}
.cover {
  content: "";
  position: absolute;
  top: 108px;
  bottom: 97px;
  right: 0;
  left: 0;
  z-index: 88888;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}

.coverMobile {
  top: 100px;
  bottom: 210px;
}

.playIconWrapper {
  position: relative;
  z-index: 999999;
}

.playIconWrapper:hover {
  cursor: pointer;
}

.coverPaused:before {
  content: "";
  position: absolute;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.4) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.topPlaceHolder {
  position: absolute;
  left: -10px;
  right: -10px;
  top: -10px;
  height: 118px;
  z-index: 9999;
  transform: translate3d(0, 0, 200px);
  background-color: var(--main-dark);
}

.bottomPlaceHolder {
  position: absolute;
  left: -10px;
  right: -10px;
  bottom: -10px;
  height: 107px;
  z-index: 9999;
  transform: translate3d(0, 0, 200px);
  background-color: var(--main-dark);
}

.bottomPlaceHolderMobile {
  bottom: 90px;
}

.playerControls {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  position: absolute;
  bottom: 97px;
  left: -2px;
  right: -2px;
  padding: 30px 30px 25px 30px;
  z-index: 99999;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.sliderWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 40px;
  position: absolute;
  right: 50px;
  padding-top: 10px;
  bottom: -3px;
}

.sliderSlide {
  height: 95px;
  margin-bottom: 8px;
  position: absolute;
  bottom: 30px;
}

.progressControllerWrapper {
  margin: 0;
  width: 100%;
  position: relative;
  transform: translateY(2px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.progressControllerWrapperMobile {
  margin: 0;
  padding: 0;
}

.playPauseWrapper {
  position: absolute;
  left: 30px;
  top: 19px;
  width: 20px;
  z-index: 99999999;
  transform: translateY(-5px);
}

.playPauseWrapper:hover {
  cursor: pointer;
}

.mySwiper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
}

.savedIcon,
.secondaryShareIcon,
.shareIcon,
.commentIcon,
.mainLikeIcon {
	@include hover();
}
