@import "../../../styles/mixins";
.wrapper {
	display: flex;
	align-items: center;
	width: 100%;
	justify-content: flex-start;
}

.wrapperBrekpoint {
	align-items: flex-start;
}

.avatarWrapper {
	display: flex;
	margin-right: 15px;
}

.inputWrapper {
	width: 100%;
	padding: 8px 10px;
	border-radius: 8px;
	border: 1px solid var(--secondary-border);
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.focused {
	border: 1px solid var(--hover-gray-nav);
}

.breckpoint {
	align-items: flex-end;
	flex-direction: column;
	padding: 8px 10px 8px 20px;
	.input {
		margin: 8px 0;
		padding: 0;
	}
}

.input {
	line-height: 30px;
	height: 33px;
	resize: none;
	overflow: hidden;
	box-sizing: border-box;
	outline: none;
	width: 100%;
	border: 0;
	outline: 0;
	max-height: 77px;
	@include text-6();
	@include fs-normal();
	color: var(--white);
	padding-top: 5px;
	padding-bottom: 7px;
	padding-left: 7px;
	padding-right: 7px;
	background: transparent;
	transition: border-color 0.2s;
	&::placeholder {
		font-size: 14px;
		color: var(--text-gray-1);
	}
}

.focused {
	.btn {
		background: var(--main-button);
	}
	.input {
		&::placeholder {
			color: var(--text-gray);
		}
	}
}

.btn {
	border-radius: 5px;
	font-size: 12px;
	font-weight: bold;
	padding: 0 10px;
	height: 35px;
	color: var(--white);
	background: var(--text-gray);

	&:hover {
		color: var(--main-dark);
		background: var(--white);
	}
}
