@import "../../../styles/mixins";

.wrapper {
  @include flex-row();
  align-items: center;
}

.headingWrapper {
	@include flex-row();
	justify-content: space-between;
	align-items: center;
}

.title {
  @include text-3();
  @include fs-normal(var(--white));

  @include media-query("mobile") {
    padding-right: 15px;
  }
}

.counter {
  @include text-5();
  @include fs-bold(var(--text-gray-1));
}

.filterButton {
	padding: 0 15px;
	position: relative;
}

.filterButtonCounter {
	position: absolute;
	left: -10px;
	top: 50%;
	transform: translateY(-55%);
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background-color: var(--white);
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 10px;
	color: var(--main-dark);
	font-weight: bold;
}
