@import "../../../styles/mixins";

.filtersModalWrapper {
	background-color: var(--main-bg);
	width: 100vw;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;

}


.headerWrapperFilters {
	position: relative;
	width: 100%;
	@include media-query('mobile') {
		margin-bottom: 40px;
	}
}

.contentWrapper {
	max-width: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	padding: 15px;
	background-color: var(--main-bg);
	@include media-query('tablet') {
		padding: 100px 15px;
	}
	@include media-query('mobile') {
		padding: 100px 15px;
	}
}

.contentWrapperMobile {
	max-width: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	padding: 0 25px;
	background-color: var(--main-bg);
}

.submitButton {
	margin-top: 40px;
	width: 200px !important;
	height: 48px !important;
	font-size: 14px;
	font-weight: bold;
	background: var(--main-button) !important;
	color: var(--white) !important;
	@include hover();
	&:hover {
		background: var(--hover-red) !important;
	}
}

.btnsWrapper {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.clearButton {
	margin-top: 50px;
	margin-right: 20px;
	width: 200px !important;
	height: 48px !important;
	background: var(--main-bg) !important;
	border: 1px solid var(--secondary-border);
	color: var(--white) !important;
	@include hover();
	&:hover {
		background: var(--white) !important;
		color: var(--main-dark) !important;
	}

	@include media-query('mobile') {
		margin-right: 0;
		margin-top: 40px;
		width: 100% !important;
	}
}

.searchWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 3%;
}

.searchWrapper svg {
  position: relative;
  transform: translateY(5px);
}

.searchWrapperMobile {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-bottom: 3%;
}

.searchWrapperMobile svg {
  position: relative;
  transform: translateY(15px);
}

.searchInputMobile,
.searchInput {
  background-color: transparent;
  padding: 12px 0;
  border: none;
  border-bottom: 1px solid #2b2b2b;
  min-width: 490px;
  margin-left: 35px;
  outline: none;
  color: var(--white);
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.searchInputMobile {
  min-width: 310px;
  margin-left: 15px;
  margin-bottom: 40px;
}

.searchInputMobile:focus,
.searchInput:focus {
  border-bottom: 1px solid var(--main-purple);
}

.searchInputMobile::-webkit-input-placeholder,
.searchInput::-webkit-input-placeholder {
  color: var(--text-gray);
}

.searchInputMobile:focus::-webkit-input-placeholder,
.searchInput:focus::-webkit-input-placeholder {
  color: #444444;
}

.periodWrapper {
  max-width: 600px;
  width: 100%;
  margin-bottom: 50px;

	@include media-query('tablet') {
		margin-bottom: 30px;
	}
	@include media-query('mobile') {
		margin-bottom: 30px;
	}
}

.periodWrapperMobile {
  max-width: 560px;
  width: 100%;
	margin-bottom: 40px;
}

.title {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
	text-align: center;
  color: var(--white);
  margin-bottom: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		position: relative;
	}
	@include media-query('tablet') {
		font-size: 18px;
		margin-bottom: 50px;
	}
}

.genresTitle {
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: center;
	color: var(--white);
	margin-bottom: 40px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	span {
		position: relative;
	}
	@include media-query('tablet') {
		font-size: 18px;
		margin-bottom: 50px;
	}
}

.titleFromPage,
.titleMobile {
	height: 31px;
	font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--white);
  margin-bottom: 30px;
  text-align: left;
  width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	span {
		position: relative;
	}
}

.titleFromPage {
	justify-content: flex-start;
}

.dateWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.dateItem {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: var(--white);
}

.dateItemMobile {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  color: var(--white);
}

.genresWrapperFromPage,
.genresWrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 600px;
  width: 100%;
}
.genresWrapperFromPage {
	align-items: flex-start;
}

.clearFiltersButton {
	display: flex;
	align-items: center;
	justify-content: center;
  	padding: 9px 11px;
	border-radius: 8px;
	font-size: 10px;
	font-weight: bold;
	margin-left: 20px;
	background-color: var(--secondary-border);
	@include hover();

	svg {
		path {
			@include hover();
		}
		margin-right: 10px;
	}
	&:hover {
	  background-color: var(--hover-gray-nav);
	  cursor: pointer;
	}
}

.genresListWrapperFromPage,
.genresListWrapperMobile,
.genresListWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.genresListWrapperFromPage {
	justify-content: flex-start;
}

.genresItem {
  padding: 10px 20px;
  border-radius: 7px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  color: var(--text-gray-1);
  border: 1px solid var(--secondary-border);
  margin: 0 8px 10px 0;
	@include hover();
	&:hover {
		color: var(--white);
		border: 1px solid var(--hover-gray-nav);
		cursor: pointer;
	}
}

.activeGenre {
  color: white;
  background-color: var(--secondary-border);
  border: 1px solid var(--secondary-border);

	&:hover {
		color: white;
		background-color: var(--secondary-border);
		border: 1px solid var(--secondary-border);
	}
}

@media screen and (min-width: 600px) and (max-width: 1000px) {
  //.genresItem {
  //  padding: 5px 13px;
  //  font-size: 13px;
  //  margin: 0 6px 6px 0;
  //}
  .title {
    font-size: 17px;
  }
}

@media screen and (min-width: 1000px) and (max-width: 1440px) {
  //.genresItem {
  //  padding: 7px 15px;
  //  font-size: 15px;
  //  margin: 0 8px 8px 0;
  //}
  .title {
    font-size: 20px;
  }
}


@media (max-height: 900px) {
	.dateWrapper {
		margin-bottom: 1%;
	}

	.title {
		margin-bottom: 30px;
	}
}

/* Enter start state */
.fadeEnter {
	transform: scale(0.1);
	opacity: 0;
}

/* Enter active state, use the 'divAnimation' keyframes */
.fadeEnterActive {
	animation: divAnimation 0.4s ease-out forwards;
}

/* Exit start state, technically not needed here as the exit animation starts from the component's current state */
.fadeExit {
	/* No initial styles required, the animation uses the component's current state */
}

/* Exit active state, use the 'divDisappear' keyframes */
.fadeExitActive {
	animation: divDisappear 0.4s ease-out forwards;
}
@keyframes divAnimation {
	0% {
		transform: scale(0.1);
		opacity: 0;
	}
	60% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes divDisappear {
	0% {
		transform: scale(1);
		opacity: 1;
	}
	40% {
		transform: scale(1.2);
		opacity: 1;
	}
	100% {
		transform: scale(0.1);
		opacity: 0;
	}
}

.clearButtonWrap {
	position: absolute;
	right: -110px;
	top: -4px;
}
