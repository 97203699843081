@import "../../styles/mixins";

.wrapper {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	background: var(--main-bg);
	min-height: 100vh;
}
.headerWrapper {
	justify-content: space-between;
}

.container {
	@include flex-column(30px);
	justify-content: space-between;
	padding: 150px 50px 70px 270px;

	@include media-query("mobile") {
		gap: 30px;
	}

	@include media-query("mobile") {
		padding: 95px 15px;
	}
}

.titleWrapper {
	@include flex-column(30px);
	@include width-full(550px);
}

.subheadingText {
	@include text-7();
	@include fs-normal();
	@include text-6(var(--text-gray));
}

.sectionWrapper {
	@include flex-column(30px);
	@include width-full(550px);
}

.sectionTitle {
	@include text-3();
	@include fs-normal(var(--white));
}

.sectionText {
	@include text-6();
	@include fs-normal();
}
