@import "../../../../styles/mixins";

.sectionWrapper {
  @include flex-column(30px);

  @include media-query("mobile") {
    @include flex-column(30px);
  }
}

.contentWrapper {
  @include flex-column(15px);

  @include media-query("mobile") {
    @include flex-column();
  }
}

.cardListWrapper {
	@include flex-row(15px);
	max-width: 100%;
	width: 100%;
	overflow-x: scroll;
	padding-right: 10px;
	padding-bottom: 3px;
}
.cardListWrapper::-webkit-scrollbar {
	transition: all 0.5s ease-in-out;
	height: 6px;
	margin-left: 20px;
}

.cardListWrapper::-webkit-scrollbar-track {
	height: 0;
	width: 0;
}

.cardListWrapper::-webkit-scrollbar-thumb {
	background-color: var(--scroll);
	border-radius: var(--br-30);
}
