@import "../../../styles/mixins";

.container {
	height: 100%;
	padding: 145px 270px 111px 270px;
	background: var(--main-bg);

	@include media-query("mobile") {
		padding: 94px 16px 94px 16px;
	}
}

.showContent {
	@include media-query("mobile") {
		height: 100vh;
		@include flex-center();
	}
}

.contentContainer {
	@include flex-column(100px);
	justify-content: center;
	align-items: center;

	@include media-query("mobile") {
		gap: 40px;
	}


	& > svg {
		@include media-query("mobile") {
			margin-top: 20px;
		}
	}
}

.textWrapper {
	@include flex-column(40px);
	text-align: center;
	width: fit-content;
}

.counterTitle {
	font-size: 90px;
	line-height: 110px;
	@include fs-bold();

	@include media-query("mobile") {
		font-size: 50px;
		line-height: 60px;
	}
}

.counterSubtitle {
	font-size: 25px;
	line-height: 30px;
	@include fs-normal();

	@include media-query("mobile") {
		@include text-3();
	}
}

.arrow {
	cursor: pointer;
}

.rotateArrow {
	transform: rotate(180deg);
}
