@import "../../../styles/mixins";

.preloaderWrapper {
	width: 100vw;
	height: 100vh;

}
.headerWrapper {
	justify-content: flex-end;
}

.navFiltersWrapper {
	position: relative;
	//z-index: 99999999999999;
}

.mobileContainer {
    scroll-snap-type: y mandatory;
    overflow-y: scroll;
    width: 100vw;
		scrollbar-width: none;
		*::-webkit-scrollbar {
			display: none;
		}
}

.item {
    scroll-snap-align: center;
    width: 100vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
}

.itemDesctop {
    scroll-snap-align: center;
    width: 100vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow-y: hidden;
}

.itemWrapper {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.itemWrapperDesctop {
    width: 75vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    position: relative;
}

.unmute {
    position: absolute;
    left: 20px;
    top: 26%;
    z-index: 99999999999;
}

.desctopPlayer {
    width: 100%;
    height: 94%;
    position: relative;
}

.bottomMenu {
    width: 100%;
    position: absolute;
    bottom: -30px;
    z-index: 999999999;
}

.playerRemoteController {
    position: fixed;
    right: 6%;
    top: 35.5%;

		svg:last-child {
			padding-top: 1px;
		}
}

.navArrowWrapper {
    display: flex;
    width: 50px;
    height: 50px;
    padding: 13px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: 100px;
    background: var(--secondary-border);
    margin: 30px 0;
    @include hover();

    &:hover {
        cursor: pointer;
        background: var(--hover-gray-nav);
        .arrowIcon {
						stroke: var(--white);
        }
    }
}

.arrowIcon {
	@include hover();
}

.chevronIcon {
    stroke: rgba(255,255,255,0.67);
    stroke-opacity: 0.33;
    @include hover();
}
.downArrow {
    transform: rotate(180deg)
}

.player {
    width: 100%;
    height: 100%;
    position: relative;
}

.desctopPlayer:after {
    content: '';
    position: absolute;
    top: -1px;
    width: 101%;
    height: 60px;
    left: -2px;
    background-color: var(--main-dark);
    z-index: 999999999;
}


.player:after {
    content: '';
    position: absolute;
    top: -1px;
    width: 100%;
    height: 25%;
    background-color: black;
    z-index: 999999999;
}

.playerHorizontal:after {
	display: none;
}

.desctopPlayer:before {
    content: '';
    position: absolute;
    bottom: -1px;
    left: -2px;
    width: 101%;
    height: 70px;
    background-color: var(--main-dark);
    z-index: 999999999;
}

.player:before {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 100%;
    height: 20%;
    background-color: black;
    z-index: 999999999;
}

.playerHorizontal:before {
	display: none;
}



.mutedIconWrapper {
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.bgCover {
    position: absolute;
    top: 19%;
    bottom: 20%;
    left: 0;
    right: 0;
    z-index: 99997;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.bottomControlWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
}
.mobileProgressController {
    width: 100%;
}

.horizontalWrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
		width: 100vw;
	  height: 100vh;
    z-index: 99999999999999999;
    background-color: black;
    display: flex;
    align-items: center;
    justify-content: center;
}

.relWrapper {
	position: relative;
	width: 100%;
	height: 100%;

	.topCover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 30px;
		background-color: black;
		z-index: 999999999;
	}
	.botCover {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 100%;
		height: 30px;
		background-color: black;
		z-index: 999999999;
	}
}

.horizontalWrapper iframe {
	transform: scale(0.5);
	transform-origin: top left;
	width: 200%;
	height: 200%;
}

.player iframe {
	transform: scale(0.5);
	transform-origin: top left;
	width: 200%;
	height: 200%;
}
