@import "../../../styles/mixins";

.statisticsWrapper {
	@include flex-column(80px);
	align-items: center;
	width: 100%;
	padding-bottom: 80px;
	border-bottom: 1px solid var(--secondary-border);

	@include media-query("mobile") {
		row-gap: 40px;
		padding-bottom: 40px;
	}
}

.wrapperWithoutBorder {
	border-bottom: none;
	padding-bottom: 0;
}

.sectionTitle {
	font-size: 50px;
	line-height: 60px;
	@include fs-normal(var(--white));

	@include media-query("mobile") {
		@include text-2();
	}
}

.statisticsCardsWrapper {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	row-gap: 60px;
	column-gap: 20px;
	width: 100%;

	@include media-query("mobile") {
		row-gap: 20px;
	}
}

.cardWrapper {
	@include flex-column(40px);
	align-items: center;
	width: 100%;

	@include media-query("mobile") {
		gap: 10px
	}
}

.cardTitle {
	@include text-2();
	@include fs-normal();

	@include media-query("mobile") {
		@include text-7();
	}
}

.cardCounter {
	font-size: 40px;
	line-height: 48px;
	@include fs-bold(var(--white));

	@include media-query("mobile") {
		@include text-5();
	}
}

.cardWrapperSmall {
	@include flex-column();
	align-items: center;
	width: 100%;
}

.cardTitleSmall {
	@include text-3();
	@include fs-normal();

	@include media-query("mobile") {
		@include text-7();
	}
}

.cardCounterSmall {
	@include text-3();
	@include fs-bold(var(--white));

	@include media-query("mobile") {
		@include text-5();
	}
}
