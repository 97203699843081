@import "../../../styles/mixins";

.modal {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: rgba(17, 17, 17, 0.9);
	z-index: 211111111111111112000;
	display: flex;
	align-items: center;
	justify-content: center;
}
.modalContent {
	padding: 20px 30px;
	border-radius: 10px;
	background-color: var(--main-gray-hover);
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-query('mobile') {
		max-width: 390px;
		flex-direction: column;
		margin: 0 15px;
		padding: 20px;
	}

	.textWrapper {
		&:dir(ltr) {
			margin-right: 25px;
			padding-right: 30px;
			border-right: 1px solid var(--secondary-border);
		}
		&:dir(rtl) {
			margin-left: 25px;
			padding-left: 30px;
			border-left: 1px solid var(--secondary-border);
		}


		@include media-query('mobile') {
			text-align: center;
			margin-right: 0;
			padding-right: 0;
			border-right: none;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid var(--secondary-border);
		}
	}

	.modalTitle {
		font-size: 14px;
		color: var(--text-gray-1);
		margin-bottom: 10px;
	}
	.modalDescription {
		font-size: 20px;
		color: var(--white);

		@include media-query('mobile') {
			font-size: 18px;
		}
	}

	.copyIconWrapper {
		@include hover();

		&:hover {
			cursor: pointer;

			svg {
				path {
					fill: var(--white);
				}
			}
		}
	}
}
