@import "../../../styles/mixins";

.wrapper {
	min-height: 100%;
	background: var(--main-bg);
}

.headerWrapper {
	justify-content: space-between;
}

.backButton {
	@include media-query("mobile") {
		display: none;
	}
}

.contentWrapper {
	@include flex-column(80px);
	align-items: center;
	@include width-full(900px);

	@include media-query("mobile") {
		gap: 40px;
	}
}
