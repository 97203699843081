@import "../../../styles/mixins";

.filterButtonWrapper {
	@include flex-row(30px);
	justify-content: space-between;
	width: 100%;

	@include media-query("mobile") {
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		justify-items: center;
		flex-wrap: wrap;
		column-gap: 10px;
	}
}

.button {
	font-size: 50px;
	line-height: 60px;
	@include fs-normal(var(--text-gray));

	@include media-query("mobile") {
		@include text-2();
		width: fit-content;
	}
}

.selectedFilter {
	color: var(--white);
}
