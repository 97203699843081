@import "../../../styles/mixins";

.modalWrapper {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 9999999999;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(17, 17, 17, 0.95);
	padding: 20px;
	overflow: auto;
}

.modalContent {
	max-width: 1084px;
	width: 100%;
	background-color: var(--main-dark);
	padding: 50px 50px 70px 50px;
	border-radius: 20px;
	margin-top: 100px;

	@include media-query("mobile") {
		padding: 20px 20px 30px 20px;
	}

	.closeModalBlock {
		text-align: right;
		width: 100%;
		margin-bottom: 50px;

		@include media-query("mobile") {
			margin-bottom: 20px;
		}
		svg {
			&:hover {
				cursor: pointer;
			}
		}
	}

	.movieDatawrap {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 30px;
	}

	.modalLeftContent {
		display: flex;
		align-items: center;
		justify-content: flex-start;

		@include media-query("mobile") {
			flex-direction: column;
			align-items: flex-start;
		}
	}
	.posterWrapper {
		border-radius: 10px;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		min-width: 100px;
		max-width: 100px;
		height: 150px;
		margin-right: 30px;

		@include media-query("mobile") {
			margin-right: 30px;
		}
	}

	.modalRightContent {
		display: flex;
		align-items: center;
		margin-left: auto;

		@include media-query("mobile") {
			margin-left: 0px;
			width: 100%;
		}
	}
	.title {
		font-size: 24px;
		margin-bottom: 20px;
		font-weight: normal;
		color: var(--white);

		@include media-query("mobile") {
			@include text-9();
			margin-bottom: 20px;
		}
	}

	.ganres {
		@include flex-row(30px);
		@include text-7();
		@include fs-bold();
		margin-bottom: 20px;

		@include media-query("mobile") {
			@include text-8();
			flex-wrap: wrap;
			gap: 15px;
			text-transform: uppercase;
			order: 3;
		}
	}

	.reit {
		width: fit-content;
		display: flex;
		align-items: center;
		justify-content: center;
		svg {
			margin-bottom: 1px;
		}
		span {
			@include fs-bold();
			@include text-4();
			display: flex;
			margin-left: 5px;
			line-height: 10px;
			padding-top: 2px;
		}

		border: 1px solid rgba(250, 250, 250, 0.3);

		padding: 9px 9px 9px 8px;
		border-radius: 8px;
		@include media-query("mobile") {
			span {
				@include text-6();
				line-height: 8px;
				padding-top: 0px;
			}
			margin-bottom: 20px;
		}
	}

	.outlineButton {
		@include text-6();
		@include fs-bold();
		gap: 15px;
		transition: all 0.2s ease-in-out;
		background: transparent;
		display: flex;
		align-items: center;
	}

	.movieContent {
		margin-bottom: 50px;
		@include media-query("mobile") {
			margin-bottom: 0px;
		}
	}

	.controllsMainButton {
		margin-left: 50px;
		width: 200px;
		font-size: 14px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		color: var(--white);
		padding: 15px 32px;
		background-color: var(--main-purple);
		border-radius: 8px;
		border: none;
		@include flex-center();
		transition: all 0.2s ease-in-out;
		@include media-query("mobile") {
			max-width: 100%;
			width: calc(100% - 30px);
			@include text-7();
		}

		&:hover {
			cursor: pointer;
			background-color: var(--hover-red);
		}
	}


	.mediaContent {
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		gap: 30px;

		@include media-query("mobile") {
			flex-direction: column;
			gap: 10px;
		}
	}

	.mediaContentLeft {
		height: 100%;
		width: 100%;
	}

	.mainImg {
		width: 766px;
		height: 445px;
		border-radius: 10px;

		@include media-query("mobile") {
			width: 100%;
			height: auto;
			margin-top: 20px;
			margin-bottom: 10px;
		}
	}

	.mediaContentRight {
		height: 100%;
		width: 100%;
		max-width: 188px;

		@include media-query("mobile") {
			max-width: 100%;
		}
	}

	.contentSwitcher {
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: 10px;
		margin-bottom: 30px;
	}

	.contentSwitcherItem {
		@include text-8();
		@include fs-bold();
		padding: 10px 20px;
		border-radius: 8px;
		min-width: 90px;
		transition: all 0.2s ease-in-out;
		background: var(--text-gray);
		display: flex;
		align-items: center;
		justify-content: center;

		@include media-query("mobile") {
			width: 49%;
		}

		@include hover();
		&:hover {
			cursor: pointer;
		}
	}

	.counter {
		margin-left: 10px;
	}

	.active {
		background: var(--white);
		color: var(--main-dark);
	}

	.cardWrapper {
		display: grid;
		grid-template-columns: auto;
		gap: 10px;
		max-width: 100%;
		max-height: 380px;
		overflow-y: scroll;
		overflow-x: hidden;
		padding-right: 3px;
		@include media-query("mobile") {
			grid-template-columns: auto auto;
			padding-bottom: 30px;
			max-height: 180px;
		}

		@media only screen and (max-width: 1000px) {
			grid-template-columns: auto auto auto auto auto;
		}

		@media only screen and (max-width: 700px) {
			grid-template-columns: auto auto auto auto;
		}
		@media only screen and (max-width: 550px) {
			grid-template-columns: auto auto auto;
		}
		@media only screen and (max-width: 400px) {
			grid-template-columns: auto auto;
		}
	}

	.cardWrapper::-webkit-scrollbar {
		width: 5px;
		margin-left: 20px;
	}

	.cardWrapper::-webkit-scrollbar-track {
		background: var(--overlay-white-26);
		border-radius: var(--br-30);
	}

	.cardWrapper::-webkit-scrollbar-thumb {
		background-color: var(--scroll);
		border-radius: var(--br-30);
	}

	.videoPrevWrapper {
		@include media-query("mobile") {
			width: 147px !important;
			object-fit: cover;
		}
		position: relative;

		&:hover {
			cursor: pointer;
		}
	}
	.image {
		display: inline-block;
		width: 183px !important;
		height: 111px;
		border-radius: var(--br-10);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;

		@include media-query("mobile") {
			width: 147px !important;
			object-fit: cover;
		}

		&:hover {
			cursor: pointer;
		}
	}

	.playIcon {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		z-index: 999999999;
	}
	.playerWrapper {
		width: 100%;
		height: 94%;
		position: relative;
	}

	.playerWrapper:after {
		content: '';
		position: absolute;
		top: -1px;
		width: 101%;
		height: 60px;
		left: -2px;
		background-color: var(--main-dark);
		z-index: 999999999;
		@include media-query("mobile") {
			content: '';
			position: absolute;
			top: -1px;
			width: 101%;
			height: 20%;
			background-color: black;
			z-index: 999999999;
		}
	}

	.playerWrapper:before {
		content: '';
		position: absolute;
		bottom: -1px;
		left: -2px;
		width: 101%;
		height: 70px;
		background-color: var(--main-dark);
		z-index: 999999999;
		@include media-query("mobile") {
			content: '';
			position: absolute;
			bottom: -1px;
			width: 101%;
			height: 20%;
			background-color: black;
			z-index: 999999999;
		}
	}

}

.headingText {
	@include text-3();
	@include fs-normal();
	color: var(--white);
	margin-bottom: 30px;
}

svg {
	@include hover();
}

svg {
	rect {
		@include hover();
	}
}
.trailerModalWrapper {
	position: fixed;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	z-index: 9999999999;
	background-color: #000000;

	.relWrapper {
		position: relative;
		width: 100%;
		height: 100vh;
	}

	.topCover {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 100px;
		background: #000000;
		z-index: 9999999999999;
	}
	.bottomCover {
		position: fixed;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100px;
		background: #000000;
		z-index: 9999999999999;
	}

	.closeTrailerBtn {
		position: absolute;
		top: 40px;
		right: 40px;
		background: transparent;
		border: none;
		z-index: 9999999999;
		@include hover();
		&:hover {
			svg {
				rect {
					@include hover();
					fill: #555555;
				}
			}
			cursor: pointer;
		}

		@include media-query("mobile") {
			svg {
				transform: scale(0.5);
			}
			top: 20px;
			right: 20px;
		}
	}

	.coversWrapper {
		position: absolute;
		width: 100%;
		height: 100%;

		@include media-query("mobile") {
			padding: 0 20px;
		}
	}
}

.plWrapper iframe {
	//transform: scale(0.9);
	//transform-origin: top left;
	//width: 110%;
	//height: 110%;

	@include media-query("mobile") {
		//transform: scale(0.5);
		//transform-origin: top left;
		//width: 200%;
		//height: 200%;
	}
}
