@import "../../../styles/mixins";

.switcherWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

.switchBtnOne {
	@include text-7();
	@include fs-bold();
	padding: 10px 20px;
	border-radius: 5px;
	min-width: 90px;
	transition: all 0.2s ease-in-out;
	background: var(--text-gray);
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-query("mobile") {
		width: 49%;
	}

	@include hover();
	&:hover {
		cursor: pointer;
	}
}

.switchBtnSecond {
	margin-left: 10px;
	@include text-7();
	@include fs-bold();
	padding: 10px 20px;
	border-radius: 5px;
	min-width: 90px;
	transition: all 0.2s ease-in-out;
	background: var(--text-gray);
	display: flex;
	align-items: center;
	justify-content: center;

	@include media-query("mobile") {
		width: 49%;
	}

	@include hover();
	&:hover {
		cursor: pointer;
	}
}

.count {
	margin-left: 10px;
}

.selectedSwitch {
	background: var(--white);
	color: var(--main-dark);
}
