@import "../../../styles/mixins";

.form__group {
  position: relative;
  padding: 0;
  width: 100%;
  display: block;
}

.form__field {
	width: 100%;
	border: 0;
	outline: 0;
	@include text-6();
	@include fs-normal();

	color: var(--white);
	padding: 0;
	background: transparent;
	transition: border-color 0.2s;

  &:focus {
    border-color: var(--main-button);
  }

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    cursor: text;
    top: 20px;
  }

	.form__label {
		@include text-6();
	}

  &:required,
  &:invalid {
    box-shadow: none;
  }
}

.form__label {
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.2s;
  @include text-7();
  @include fs-normal();
  color: var(--secondary-gray);
}

.iconRight {
  position: absolute;
  top: 70%;
  right: 10px;
  transform: translateY(-50%);
}
